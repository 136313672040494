

/* ----------------------------------------------------------------------------
 * Variables
 * ------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------
 * Colors New
 * ------------------------------------------------------------------------- */

// Dark Transparent Color Palette
$dark-1   : rgba(0, 0, 0, 0.89);
$dark-2   : rgba(0, 0, 0, 0.85);
$dark-3   : rgba(0, 0, 0, 0.8);
$dark-4   : rgba(0, 0, 0, 0.66);
$dark-5   : rgba(0, 0, 0, 0.52);
$dark-6   : rgba(0, 0, 0, 0.34);
$dark-7   : rgba(0, 0, 0, 0.24);
$dark-8   : rgba(0, 0, 0, 0.12);
$dark-9   : rgba(0, 0, 0, 0.05);
$dark-10  : rgba(0, 0, 0, 0.03);

$default-text-color: $dark-3 !default;

// Light Transparent Color Palette
$light-0  : rgba(255, 255, 255, 0.98);
$light-1  : rgba(255, 255, 255, 0.89);
$light-2  : rgba(255, 255, 255, 0.85);
$light-3  : rgba(255, 255, 255, 0.8);
$light-4  : rgba(255, 255, 255, 0.66);
$light-5  : rgba(255, 255, 255, 0.52);
$light-6  : rgba(255, 255, 255, 0.34);
$light-7  : rgba(255, 255, 255, 0.24);
$light-8  : rgba(255, 255, 255, 0.12);
$light-9  : rgba(255, 255, 255, 0.05);
$light-10 : rgba(255, 255, 255, 0.03);

// Non-Transparent Color Palette
$blue   : #1767ef;

$black    : #000000;
$white    : #ffffff;

$bg-1     : #1c1b1b;
$bg-2     : #262626;
$bg-3     : #333333;
$bg-4     : #575757;
$bg-5     : #7b7b7b;
$bg-6     : #a8a8a8;
$bg-7     : #c1c1c1;
$bg-8     : #dfdfe0;
$bg-9     : #f1f1f1;
$bg-10    : #f8f8f9;



/* ----------------------------------------------------------------------------
 * Spacing
 * ------------------------------------------------------------------------- */

$m-s-1  : 0;
$m-s-2  : 4px;
$m-s-3  : 8px;
$m-s-4  : 16px;
$m-s-5  : 24px;
$m-s-6  : 32px;
$m-s-7  : 40px;
$m-s-8  : 48px;
$m-s-9  : 56px;
$m-s-10 : 72px;
$m-s-11 : 96px;


$d-s-1  : 4px;
$d-s-2  : 8px;
$d-s-3  : 16px;
$d-s-4  : 24px;
$d-s-5  : 32px;
$d-s-6  : 40px;
$d-s-7  : 48px;
$d-s-8  : 56px;
$d-s-9  : 72px;
$d-s-10 : 96px;
$d-s-11 : 128px;

$stickyheader-m: 64px;
$stickyheader-d: 88px;



/* ----------------------------------------------------------------------------
 * Max Content Widths
 * ------------------------------------------------------------------------- */

$website-width              : 1248px; // max default width
$ten-columns-width          : 1040px; // max default width
$content-width              : 736px; // max content width on single posts (incl 24px padding left and right)
$content-width-nopadding    : 688px; // max content width on single posts (incl 24px padding left and right)
$content-width-plus-padding : 784px; // max content width on single posts (incl additional 24px padding)
$bg-content-width           : 832px; // max content width, if has background
$col5-nopadding             : 472px; // 5 column width without padding
$col7                       : 728px; // 7 column width padding
