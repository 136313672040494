@charset "UTF-8";
/*
Theme Name: Pukeko
Theme URI: https://www.elmastudio.de/en/themes/pukeko/
Author: Elmastudio
Author URI: http://www.elmastudio.de/en/
Description:
Version: 1.0.1
License: GNU General Public License v2 or later
License URI: LICENSE
Text Domain: pukeko
Tags:

* @version 1.0.1

*/

/* ----------------------------------------------------------------------------
 * Mixins & Variables
 * ------------------------------------------------------------------------- */
@import "partials/mixins";
@import "partials/variables";

/* ----------------------------------------------------------------------------
 * Normalize & Santize
 * ------------------------------------------------------------------------- */
@import "vendors/normalize";
@import "vendors/sanitize";


/*--------------------------------------------------------------
# Nur zum Arbeiten, wird gelöscht
--------------------------------------------------------------*/
.posts-container {
	opacity: 1 !important;
}

.pukeko-sample {
	background: lightgreen;
}

.divider {
	background: lightgreen;
}


/* ----------------------------------------------------------------------------
 * SVG Icons base styles
 * ------------------------------------------------------------------------- */

.icon {
	display: inline-block;
	fill: currentColor;
	position: relative; /* Align more nicely with capital letters */
	top: 0;
	vertical-align: middle;
	text-align: center;
	stroke-width: 0;
	stroke: currentColor;
	width: 22px;
	height: 22px;
	padding: 1px;
	background-repeat: no-repeat;
	background-image: url(assets/images/sprite.svg);
}

/* Sticky Post Read More Icon */
.sticky-more .icon-arrow-right {
	position: absolute;
	display: block;
	top: 17px;
	left: 17px;
	@include r(lt) {
		top: 25px;
		left: 25px;
	}
}

/* Blog Pagination Icons */
.site-main .navigation .prev .icon-arrow-left,
.site-main .navigation .next .icon-arrow-right {
	position: absolute;
	left: 13px;
	top: 13px;
	@include r(lt) {
		top: 17px;
		left: 17px;
	}
}

/* Social Icons */
.social-header-nav .icon {
	fill: rgba(255, 255, 255, 0.3);
	width: 30px;
	height: 30px;
 @include r(lt) {
		fill: $dark-1;
		width: 22px;
		height: 22px;
 }
}

.social-header-nav a:hover .icon {
	fill: $white;
	@include r(lt) {
		 fill: $dark-1;
	}
}


/* Dropdown Icons - Main Menu */
.main-navigation .icon {
	display: none;
	width: 14px;
	height: 14px;
	padding: 0;
	margin-left: 0;
	margin-top: -3px;
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
	transition: transform 150ms ease-in-out;
	transform-origin: center center;
	@include r(sd) {
		display: inline;
		position: relative;
		right: auto;
		top: auto;
		padding: 0 0 0 3px;
		margin-left: 3px;
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}

.main-navigation .dropdown-toggle .icon {
	display: inline !important;
	fill: $white;
}

.main-navigation .sub-menu .menu-item-has-children .icon {
	display: none;
	margin: 0;
	padding: 0;
	fill: rgba(255, 255, 255, 0.3);
	@include r(sd) {
		display: inline;
		position: absolute;
		top: 20px;
		right: 16px;
		fill: currentcolor;
		-webkit-transform: rotate(-90deg);
		-ms-transform: rotate(-90deg);
		transform: rotate(-90deg);
		vertical-align: middle;
	}
}

.main-navigation .dropdown-toggle.toggled-on .icon {
			transform: rotate(0deg);
}


/* ----------------------------------------------------------------------------
 * Structure
 * ------------------------------------------------------------------------- */

/* global box-sizing */
*,
*:after,
*:before {
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-font-smoothing: antialiased;
		font-smoothing: antialiased;
		text-rendering: optimizeLegibility;
}

/* clear */
.clear:before, .clear:after, .entry-content:before, .entry-content:after, .comment-content:before, .comment-content:after, .site-header:before, .site-header:after, .site-content:before, .site-content:after, .site-footer:before, .site-footer:after {
	content: "";
	display: table;
	table-layout: fixed;
}

.clear:after, .entry-content:after, .comment-content:after, .site-header:after, .site-content:after, .site-footer:after {
	clear: both;
}
.cf:before, .cf:after {content: " "; /* 1 */ display: table; /* 2 */}
.cf:after {clear: both;}

.clearfix:after, .clear:after {
	clear: both;
	content: ' ';
	display: block;
	font-size: 0;
	line-height: 0;
	visibility: hidden;
	width: 0;
	height: 0;
}

/* ----------------------------------------------------------------------------
 * #Grid
 * ------------------------------------------------------------------------- */
.container-standard, .no-sidebar.no-title .site-main, .page .site-content, .error404 .site-content, .blog .site-content, .archive .site-content, .search .site-content, .single-post .site-content, .footer-wrap, .related, .single-post .nav-links, .single-post.sidebar-hide-post .entry-header, .single-post.sidebar-hide-post .post-thumb {
	max-width: $website-width;
	@include push--auto;
}

.single-post.sidebar-hide-post .site-content {
	max-width: 100%;
}

.single-post.sidebar-hide-post .col {
	max-width: $content-width;
	@include push--auto;
}

.single-post.sidebar-hide-post .comments-area,
.nosidebar-notitle-page .comments-area {
	max-width: $bg-content-width;
	@include push--auto;
}

.blog.sidebar-hide.blog-1-column .site-content, .archive.sidebar-hide.blog-1-column .site-content, .search.sidebar-hide.blog-1-column .site-content {
	max-width: $bg-content-width;
}

.row, .column, .col {
	box-sizing: border-box;
}

.row:before, .row:after {
	content: " ";
	display: table;
}

.row:after {
	clear: both;
}

/* Grid Columns */
.posts-container,
.footer-widget-wrap,
.header-infobar {
	display: flex;
	flex-wrap: wrap;
}

.col, .posts-container .type-post, .posts-container .type-page {
	position: relative;
	padding-left: $m-s-4;
	padding-right: $m-s-4;
	float: left;
	@include r(lt) {
		padding-left: $d-s-4;
		padding-right: $d-s-4;
	}
}

/* 1-Column */
.blog-1-column .posts-container .type-post,
.blog-1-column .posts-container .type-page {
	width: 100%;
	flex-grow: 0;
	flex-basis: 100%; // 1-column on mobile
	clear: both;
	float: none;
}

/* 2-Columns */
.s6, .posts-container .hentry, .blog-2-column .posts-container .hentry,
.single-post.sidebar-show .related-post,
.single-post.sidebar-show-post .related-post {
	width: 100%; // 1-column on mobile
	flex-grow: 0;
	@include r(st) {
		flex-basis: 49.9999998%; /* 2-column on small tablets */
	}
}

/* 3-Columns */
.s4, .blog-3-column .posts-container .hentry,
.single-post.sidebar-hide-post .related-post,
.footer-widget,
.header-infobar .col {
	width: 100%; // 1-column on mobile
	flex-grow: 0;
	@include r(st) {
		flex-basis: 49.9999998%; /* 2-column on small tablets */
	}
	@include r(sd) {
		flex-basis: 33.3333332%; /* 2-column on small desktops */
	}
}

/* Blog Sticky Post */

.blog .posts-container .sticky {
	display: block;
	flex-basis: 100% !important;
	padding-bottom: 0;
	margin-bottom: $m-s-9;
	padding-left: 0;
		padding-right: 0;
		margin-left: 16px;
		margin-right: 16px;
	border-bottom: 1px solid $dark-9;
	@include r(lt) {
		margin-left:24px;
		margin-right: 24px;
		margin-bottom: $d-s-9;
	}
	@include r(d) {
		padding-bottom: $d-s-9;
	}
}

.blog.sidebar-show.sidebar-show-post .posts-container .sticky {
	@include r(d) {
		padding-bottom: $d-s-10;
	}
}

.sticky .entry-header,
.sticky .entry-summary {
	max-width: 600px;
}
.posts-container .sticky .entry-header {
	float: left;
	padding: 0 0 $m-s-9;
	@include r(lt) {
		padding: 0 $d-s-9 $d-s-6 0;
	}
	@include r(d) {
		width: 40%;
	}
}
.sidebar-show .posts-container .sticky .entry-header {
	width: auto;
	float: none;
	@include r(lt) {
		padding: 0 0 $d-s-9;
	}
}

.sticky .entry-summary {
	@include r(lt) {
		padding: $d-s-6 0 0 0;
	}
}

.posts-container .sticky .entry-meta {
	padding-bottom: $m-s-5;
	@include r(lt) {
		padding-bottom: $d-s-5;
	}
}

.blog .posts-container .sticky .entry-meta a {
	@include f1-uppercase1-m;
	@include r(lt) {
		@include f1-uppercase1-d;
	}
}

.single-post .posted-on a,
.sticky .posted-on a {
	padding-left: 4px;
}

.sticky .more-link {
	display: none;
}

.blog .sticky h2.entry-title,
.product-content .section-title {
	@include f2-h3-m;
	@include r(lt) {
		@include f2-h3-d;
	}
}

.blog .sticky .post-thumb {
	padding: 0 0 $m-s-7;
	@include r(lt) {
		padding: 0 0 $d-s-7;
	}
	@include r(d) {
		width: 60%;
		float: right;
		padding: 0;
	}
}
.sticky .post-thumb {
	position: relative;
}
.sticky .post-thumb-img .sticky-more {
	display: block;
	position: absolute;
	bottom: $m-s-5;
	left: $m-s-5;
	background: $white;
	width: $m-s-9;
	height: $m-s-9;
	text-indent: -99999px;
	@include r(lt) {
		width: $d-s-9;
		height: $d-s-9;
		bottom: $d-s-5;
		left: $d-s-5;
	}
}

.sticky .post-thumb-img:hover .sticky-more:before {
	-webkit-transform: scaleY(1);
	transform: scaleY(1);
	-webkit-transform-origin: center bottom;
	transform-origin: center bottom;
}

.sticky .post-thumb-img .sticky-more:before {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	content: "";
	display: block;
	background: $dark-9;
	-webkit-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transform-origin: center top;
	transform-origin: center top;
	transition: -webkit-transform .2s ease-in;
	transition: transform .2s ease-in;
	transition: transform .2s ease-in,-webkit-transform .2s ease-in;
}

.blog .sticky .post-thumb-img {
	@include transition-posthover;
}

.blog .sticky .post-thumb-img:hover {
	@include posthover-scale-default;
	@include shadow-m;
}

.sidebar-show .sticky .post-thumb {
	width: auto;
	float: none;
}


.blog .sticky .entry-footer {
	@include r(d) {
		position: absolute;
		bottom: 0;
	}
}

.posts-container .sticky .entry-footer {
	padding: 0 0 $m-s-5;
	@include r(lt) {
		padding: 0 0 $d-s-4;
	}
	@include r(d) {
		padding: 0 0 $d-s-9;
	}
}

.sidebar-show.sidebar-show-post .posts-container .sticky .entry-footer {
	@include r(d) {
		padding: 0 0 $d-s-4;
	}
}

.sticky .byline {
	display: inline-block;
}

/* Footer Widgets Grid */
.footer-widget {
	flex-basis: 100%; // 1-column on mobile
	flex-grow: 0;
	@include r(lt) {
		flex-grow: 3;;
		flex-basis: 33.3%; /* 2-column on small desktops */
	}
}





.s1 {
	width: 8.33333333%;
}

.s2 {
	width: 16.6666666%;
}
.s5 {
	width: 41.6666665%;
}
.s7 {
	width: 58.3333331%;
}

.s8 {
	width: 66.6666665%;
}

.s9 {
	width: 74.9999998%;
}

.s10 {
	width: 83.3333331%;
}

.s11 {
	width: 91.6666665%;
}

.s12, .blog-1-column .posts-container .hentry {
	width: 100%;
	float: none;
}

.pull-1 {
	margin-right: 8.33333333%;
}

.pull-2 {
	margin-right: 16.6666666%;
}

.pull-3 {
	margin-right: 24.9999999%;
}

.pull-4 {
	margin-right: 33.3333332%;
}

.pull-5 {
	margin-right: 41.6666665%;
}

.pull-6 {
	margin-right: 49.9999998%;
}

.pull-7 {
	margin-right: 58.3333331%;
}

.pull-8 {
	margin-right: 66.6666665%;
}

.pull-9 {
	margin-right: 74.9999998%;
}

.pull-10 {
	margin-right: 83.3333331%;
}

.pull-11 {
	margin-right: 91.6666665%;
}

.push-1 {
	margin-left: 8.33333333%;
}

.push-2 {
	margin-left: 16.6666666%;
}

.push-3 {
	margin-left: 24.9999999%;
}

.push-4 {
	margin-left: 33.3333332%;
}

.push-5 {
	margin-left: 41.6666665%;
}

.push-6 {
	margin-left: 49.9999998%;
}

.push-7 {
	margin-left: 58.3333331%;
}

.push-8 {
	margin-left: 66.6666665%;
}

.push-9 {
	margin-left: 74.9999998%;
}

.push-10 {
	margin-left: 83.3333331%;
}

.push-11 {
	margin-left: 91.6666665%;
}

.divider {
	display: block;
	height: 40px;
}


/* ----------------------------------------------------------------------------
 * Typography
 * ------------------------------------------------------------------------- */

/* Main Font Styles */
body, button, input, select, textarea, .textwidget p, .archive .page-header p, .search .page-header p, .teammember-content-wrap h2.section-title span {
	@include f1;
	@include f1-body3-m;
	color: $dark-3;
	@include r(lt) {
		@include f1-body3-d;
	}
}

/* Main Fonts (f1 and f2) */
.f1, .sansserif, .hentry .entry-meta, .hentry .entry-meta a, .authorbox-title, .comment-respond h3.comment-reply-title,
.pukeko_sectiontitle .section-title, .elementor-widget-wp-widget-pukeko-sectiontitle-widget .section-title {
	@include f1;
}

.f2, .serif {
	@include f2;
}

.authorbox-title {
	@include f1-uppercase2-m;
	@include r(lt) {
		@include f1-uppercase2-d;
	}
}

.comment-reply-title #cancel-comment-reply-link {
	@include f1-body4-2-m;
	@include r(lt) {
		@include f1-body4-2-d;
	}
}

h3.related-title,
.posts-container .hentry .entry-footer,
.posts-container .hentry .entry-footer a,
.single-post .navigation a span.nav-subtitle,
.tailor-grid__item .teammember-content-wrap h2.section-title span,
.elementor-widget-wp-widget-pukeko-teammember-widget .teammember-content-wrap h2.section-title span {
	@include f1-body4-m;
	@include r(lt) {
		@include f1-body4-d;
	}
}

.posts-container .sticky .entry-footer,
.posts-container .sticky .entry-footer a {
	color: $dark-3;
}

.comment-list .comment-metadata,
form label {
	@include f1-body5-m;
	@include r(lt) {
		@include f1-body5-d;
	}
}

form label {
	display: block;
	padding: 0 0 $m-s-2;
	@include r(lt) {
		padding: 0 0 $d-s-2;
	}
}

/* Headlines */
h1, h2, h3, h4, h5, h6 {
	@include f2;
	margin: 0;
	clear: both;
	color: $dark-1;
	font-weight: normal;
}

h1,
.pukeko_pagetitle .section-title,
.elementor-widget-wp-widget-pukeko-pagetitle-widget .section-title {
	@include f2-h1-m;
	@include r(lt) {
		@include f2-h1-d;
	}
}

h2 {
	@include f2-h2-m;
	@include r(lt) {
		@include f2-h2-d;
	}
}

.widget-area .widget-title, .archive .page-header h1.page-title, .search .page-header h1.page-title, .related-entry-cats, .header-infobar h3, .tailor-content p strong, .elementor-text-editor p strong {
	@include f1-widgettitle-m;
	@include r(lt) {
		@include f1-widgettitle-d;
	}
}

h3 {
	@include f2-h3-m;
	@include r(lt) {
		@include f2-h3-d;
	}
}

h4 {
	@include f2-h4-m;
	@include r(lt) {
		@include f2-h4-d;
	}
}

h5, .blog h2.entry-title, .archive h2.entry-title, .search h2.entry-title, .single-post .navigation a,
.single-post.sidebar-show-post .navigation .nav-title {
	@include f2-h4-m;
	color: $dark-1;
	@include r(lt) {
		@include f2-h4-d;
	}
}

h5 {
	@include f2-h5-m;
	@include r(lt) {
		@include f2-h5-d;
	}
}

h6 {
	@include f2-h6-m;
	@include r(lt) {
		@include f2-h6-d;
	}
}

h3.sansserif {
	@include f1-h3-m;
	@include r(lt) {
		@include f1-h3-d;
	}
}

.pukeko_teammember h2.section-title,
.elementor-col-100 .elementor-widget-wp-widget-pukeko-teammember-widget h2.section-title {
	@include f1-h4-m;
	@include r(lt) {
		@include f1-h4-d;
	}
}

h2.comments-title, h5.sansserif, h3.author-name, h2.section-title, .null-instagram-feed h2.widget-title, .widget_mc4wp_form_widget h2.widget-title,
.tailor-grid__item .pukeko_teammember h2.section-title,
.elementor-widget-wp-widget-pukeko-sectiontitle-widget h2.section-title {
	@include f1-h5-m;
	@include r(lt) {
		@include f1-h5-d;
	}
}

h6.sansserif,
h3.comment-reply-title {
	@include f1-h6-m;
	@include r(lt) {
		@include f1-h6-d;
	}
}

.pukeko_sectiontitle .section-title,
.elementor-widget-wp-widget-pukeko-sectiontitle-widget .section-title {
@include f1-h4-m;
	@include r(lt) {
		@include f1-h4-d;
	}
}


/* Padding for Headlines in Content Area */
.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6 {
	margin-bottom: $m-s-6;
	@include r(lt) {
		margin-bottom: $d-s-6;
	}
}

.entry-content h4,
.entry-content h5,
.entry-content h6 {
	font-weight: bold;
}

p,
address {
	margin: 0 0 $m-s-6;
	@include r(lt) {
		margin: 0 0 $d-s-6;
	}
}


.widget-area-default p {
	margin: 0 0 $m-s-4;
	@include r(lt) {
		margin: 0 0 $d-s-4;
	}
}

.entry-content .teammember-content-wrap p,
.entry-content .elementor-col-100 .elementor-widget-wp-widget-pukeko-teammember-widget .teammember-content-wrap p, {
	margin: 0;
	@include f1-body2-m;
	@include r(lt) {
		@include f1-body2-d;
	}
}

.entry-summary p,
.entry-content .tailor-grid__item .teammember-content-wrap p,
.entry-content .elementor-widget-wp-widget-pukeko-teammember-widget .teammember-content-wrap p,
.entry-content .tailor-content p,
.entry-content .elementor-text-editor p {
	margin: 0;
	@include f1-body3-m;
	@include r(lt) {
		@include f1-body3-d;
	}
}

.single-post .entry-meta,
.comment-list b.fn {
	@include f1-body4-m;
	@include r(lt) {
		@include f1-body4-d;
	}
}

.entry-content p.text-l, .entry-content p.intro, .entry-content .quote-big p,
.entry-content .pull-left, .entry-content .pull-right {
	@include f1-body1-m;
	@include r(lt) {
		@include f1-body1-d;
	}
}

p.-text-m, .entry-content p, .entry-content ul, .entry-content ol {
	@include f1-body2-m;
		@include r(lt) {
			@include f1-body2-d;
		}
}

.single-post .entry-cats {
	@include f1-uppercase1-m;
	color: $dark-1;
		@include r(lt) {
			@include f1-uppercase1-d;
		}
}

dfn, em, i {
	font-style: italic;
}

blockquote {
	margin: 0;
	padding-left: $m-s-6;
	border-left: 1px solid $blue;
	@include r(lt) {
		padding-left: $d-s-6;
	}
}

blockquote cite {
	display: block;
	padding: $m-s-4 0 0 0;
	font-style: normal;
	@include f1-body3-2-m;
	@include r(lt) {
		@include f1-body3-2-d;
		padding: $d-s-4 0 0 0;
	}
}

.pull-left {
	margin: 2px $d-s-7 $d-s-5 0;
	max-width: 320px;
	float: left;
}

.pull-right {
	margin: 2px 0 $d-s-5 $d-s-7;
	max-width: 320px;
	float: right;
}

pre {
	background: $bg-9;
	max-width: 100%;
	overflow: auto;
	padding: $m-s-3;
	margin-bottom: $m-s-6;
	@include f-code;
	@include f1-btn1-m;
	@include r(lt) {
		@include f1-btn1-d;
		padding: $d-s-3;
		margin-bottom: $d-s-6;
	}
}

code, kbd, tt, var {
	font-family: Monaco, Consolas, monospace;
	@include f1-btn2-m;
	@include r(lt) {
		@include f1-btn2-d;
	}
}

abbr, acronym {
	text-decoration: none;
	border-bottom: 1px dotted #666;
	cursor: help;
}

mark, ins {
	background-color: rgba(63, 192, 224, .1);
	text-decoration: none;
}

big {
	font-size: 125%;
}

.dropcap {
	font-size: 98px;
	font-weight: 300;
	float: left;
	padding: 0 10px 0 0;
	line-height: .9;
	color: $dark-1;
	@include r(lt) {
		font-size: 124px;
		margin-left: -11px;
		line-height: .77;
	}
}


/* ----------------------------------------------------------------------------
 * Elements
 * ------------------------------------------------------------------------- */

body {
	background: $white;
	/* Fallback for when there is no custom background color defined. */
}

blockquote:before, blockquote:after, q:before, q:after {
	content: "";
}

blockquote, q {
	quotes: "" "";
}

.entry-content hr {
	width: $m-s-9;
	margin-bottom: $m-s-6;
	border-top: 1px solid $dark-1;
	@include r(lt) {
		width: $d-s-9;
		margin-bottom: $d-s-6;
}
}

ul, ol {
	margin: 0;
}

ul {
	list-style: disc;
}

ol {
	list-style: decimal;
}

ol ol {
	list-style: lower-alpha;
}

#colophon ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

#secondary ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

li > ul, li > ol {
	margin-bottom: 0;
	margin-left: $m-s-4;
	@include r(lt) {
		margin-left: $d-s-4;
	}
}

.entry-content ul, .entry-content ol {
	margin: 0 0 $m-s-6 $m-s-4;
	@include r(lt) {
		margin: 0 0 $d-s-6 $d-s-4;
	}
}

.entry-content ul ul, .entry-content ol ol {
	margin-bottom: 0;
}

dt {
	font-weight: bold;
}

dd {
	margin: 0 $m-s-4 $m-s-4;
	@include r(lt) {
		margin: 0 $d-s-4 $d-s-4;
	}
}

img {
	height: auto;
	/* Make sure images are scaled correctly. */
	max-width: 100%;
	/* Adhere to container width. */
}

figure {
	margin: $m-s-4 0;
	@include r(lt) {
		margin: $d-s-4 0;
	}
	/* Extra wide images within figure tags don't overflow the content area. */
}

table {
	width: 100%;
	margin: 0 0 $m-s-4;
	@include r(lt) {
		margin: 0 0 $d-s-4;
	}
}

/* ----------------------------------------------------------------------------
 * Accessibility
 * ------------------------------------------------------------------------- */
/* Text meant only for screen readers. */
.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	-webkit-clip-path: inset(50%);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	word-wrap: normal !important;
}

.screen-reader-text:focus {
	background-color: $bg-9;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
	/* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
	outline: 0;
}

/* ----------------------------------------------------------------------------
 * Alignments
 * ------------------------------------------------------------------------- */
.alignleft {
	display: inline;
	float: left;
	margin: $d-s-2 $m-s-7 $m-s-5 0;
	@include r(lt) {
		margin: $d-s-2 $d-s-7 $d-s-5 0;
	}
}

.alignright {
	display: inline;
	float: right;
	margin: $d-s-2 0 $m-s-5 $m-s-7;
	@include r(lt) {
		margin: $d-s-2 0 $d-s-5 $d-s-7;
	}
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.wp-caption.alignleft .wp-caption-text,
.wp-caption.alignright .wp-caption-text {
	margin-bottom: 0;
}

/* ----------------------------------------------------------------------------
 * Forms
 * ------------------------------------------------------------------------- */
input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="number"], input[type="tel"], input[type="range"], input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"], input[type="color"] {
	width: 100%;
	background: $white;
	border: 1px solid $dark-8;
	height: $m-s-8;
	padding: 0 16px;
	@include hide-rounded;
	@include transition-standard;
	@include input-placeholder {
		color: $dark-6;
	}
	@include r(lt) {
		height: $d-s-8;
		padding: 0 16px;
	}
}
.nav-container input[type="search"] {
	@include input-placeholder {
		color: rgba(255, 255, 255, .3);
	}
	@include r(lt) {
		@include input-placeholder {
			color:  $dark-6;
		}
	}
}
.nav-container input[type="search"]:active,
.nav-container input[type="search"]:focus {
		color: $white;
	@include r(lt) {
			color:  $dark-6;
	}
}

textarea {
	width: 100%;
	background: $white;
	border: 1px solid $dark-8;
	padding: 16px;
	@include hide-rounded;
	@include transition-standard;
	@include input-placeholder {
		color: $dark-6;
	}
}

input[type="text"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="number"]:focus, input[type="tel"]:focus, input[type="range"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="week"]:focus, input[type="time"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="color"]:focus, textarea:focus {
	color: $dark-3;
	outline: none;
	border-color: $blue;
}

.comment-respond textarea,
.comment-respond input {
	border: none;
}

.comment-respond textarea:focus,
.comment-respond input:focus {
	background: $white;
	@include shadow-formsfocus;
}

.comment-respond textarea {
		height: 200px;
}

form p,
.entry-content .elementor-text-editor form p {
	margin: 0 0 24px;
}

form p.comment-form-comment {
	margin: 0;
	line-height: 0;
}

/* ----------------------------------------------------------------------------
 * Buttons General
 * ------------------------------------------------------------------------- */

button, input[type="button"], input[type="reset"], input[type="submit"] {
	@include hide-rounded;
	@include transition-standard;
	@include btn;
	@include btn-m;
}

button, input[type="button"] {
	@include btn-secondary;
}

input[type="submit"] {
	@include btn-primary;
}


/* Buttons without Margin Bottom */
.bg {
	margin-bottom: 0;
}

button:focus, input[type="button"]:focus, input[type="reset"]:focus, input[type="submit"]:focus, button:active, input[type="button"]:active, input[type="reset"]:active, input[type="submit"]:active {
	outline: none;
	box-shadow: none;
}


/* ----------------------------------------------------------------------------
 * Button Styles
 * ------------------------------------------------------------------------- */

.btn,
.comment-respond input[type="submit"] {
	@include btn;
}

/* Primary Buttons */
a.btn-primary,
.btn-primary a,
.widget_mc4wp_form_widget input[type="submit"] {
	@include btn-primary;
}

/* Secondary Buttons */
.btn-secondary a,
a.btn-secondary,
.tagcloud a,
.entry-tags a {
	@include btn-secondary;
}

/* Outline Buttons */
.btn-ghost a,
a.btn-ghost,
.comment-respond input[type="submit"] {
	@include btn-ghost;
}

.comment-respond input[type="submit"] {
	background: none;
	color: $dark-1;
}

/* Ghost Buttons */
a.btn-flat,
.btn-flat a,
.null-instagram-feed p.clear a {
	@include btn-flat;
}


/* Button Sizes */
.btn-xl {
	@include btn-xl;
}
.btn-l {
	@include btn-l;
}
.btn-m {
	@include btn-m;
}
.btn-s {
	@include btn-s;
}
.btn-xs {
	@include btn-xs;
}

.btn-xs.btn-ghost {
	padding-top: 13px;
}

.btn-s.btn-ghost {
	padding-top: 17px;
}

.btn-m.btn-ghost {
	padding-top: 21px;
}

.btn-l.btn-ghost {
	padding-top: 30px;
}

.btn-xl.btn-ghost {
	padding-top: 37px;
}


/* ----------------------------------------------------------------------------
 * Tables
 * ------------------------------------------------------------------------- */
table {
	border-collapse: collapse;
	margin: 0 0 $m-s-4;
	width: 100%;
	@include r(lt) {
		margin: 0 0 $d-s-4;
	}
}

thead th {
	border-bottom: 2px solid $dark-9;
	padding-bottom: $m-s-2;
	@include r(lt) {
		margin: 0 0 $d-s-2;
	}
}

th {
	padding: $m-s-2;
	text-align: left;
	@include r(lt) {
		padding: $d-s-2;
	}
}

tr {
	border-bottom: 1px solid $dark-9;
}

td {
	padding: $m-s-2;
	@include r(lt) {
		padding: $d-s-2;
	}
}

th:first-child, td:first-child {
	padding-left: 0;
}

th:last-child, td:last-child {
	padding-right: 0;
}

/* ----------------------------------------------------------------------------
 * Links + Colors
 * ------------------------------------------------------------------------- */
a, textarea .required,
form label {
	color: inherit;
}
.entry-content p a,
.entry-content li a,
.comment-content p a,
.comment-content li a,
.hero-text a {
	border-bottom: 1px solid $blue;
	@include transition-standard;
}
.entry-content p a:hover,
.entry-content li a:hover,
.comment-content p a:hover,
.comment-content li a:hover,
.hero-text a:hover {
color: $blue;
}

/* Improve readability of focused elements when they are also in an active/hover state. */
a:hover, a:active {
	outline: 0;
}

.main-navigation a, .site-title a, .single-post .entry-cats, .single-post .entry-cats a, .single-post .entry-meta, .single-post .entry-meta a, h2.entry-title a, .posts-container .hentry .entry-meta a, .comment-list b.fn a, .comment-respond .required, .teammember-social-wrap a {
	color: $dark-1;
	fill: $dark-1;
}

.single-post .entry-cats a,
.single-post .entry-meta a,
.posts-container .hentry .entry-meta a,
.posts-container .sticky .entry-footer a,
#secondary li a,
#secondary p a,
.authorbox-content a,
.comment-body .reply a {
@include transition-standard;
}

.single-post .entry-cats a:hover,
.single-post .byline a:hover,
.single-post .entry-meta a:hover,
.single-post .entry-comments:hover .bubble-icon .icon,
.posts-container .hentry .entry-meta a:hover,
.posts-container .sticky .entry-footer a:hover,
#secondary li a:hover,
#secondary p a:hover,
.authorbox-content a:hover,
.teammember-social-wrap a:hover {
@include transition-standard;
	color: $dark-6;
	fill: $dark-6;
}

#colophon {
	color: $light-6;
	background: $bg-2;
}

#colophon a,
#colophon .widget_mc4wp_form_widget p {
	@include transition-standard;
	color: $white;
}

#colophon a:hover {
	color: $light-6;
}

.footer-menu-wrap .social-nav ul li a,
.social-footer-nav .icon,
.social-header-nav a,
.social-header-nav .icon {
	@include transition-standard;
}

.social-header-nav a:hover .icon {
	color: $white;
	fill: $white;
	@include transition-standard;
	@include r(lt) {
		color: $dark-6;
		fill: $dark-6;
	}
}

.site-footer a:hover,
.site-footer .widget-area .footer-widget h2,
.social-footer-nav .icon, {
	color: $light-6;
	fill: $light-6;
}

.footer-dark .social-footer-nav  ul li a:hover .icon {
	fill: $white;
}

.comment-list .comment-metadata, .comment-list .comment-metadata a, .logged-in-as, .logged-in-as a, .comment-notes, .comment-body .reply, .wp-caption, .widget_search input[type="search"], .comment-body p.comment-awaiting-moderation {
	color: $dark-6;
	@include transition-standard;
}
.comment-list .comment-metadata a:hover,
.logged-in-as a:hover {
	color: $dark-3;
}


/* ----------------------------------------------------------------------------
 * Link Hovers
 * ------------------------------------------------------------------------- */
.single-post .navigation a,
.navigation a {
	@include transition-posthover;
 }

.single-post .navigation a:hover,
.navigation a:hover,
.navigation .current {
	@include shadow-m;
	@include posthover-scale-default;
}

.navigation .current {
	color: $dark-1;
}


/* ----------------------------------------------------------------------------
 * Media
 * ------------------------------------------------------------------------- */
.page-content .wp-smiley, .entry-content .wp-smiley, .comment-content .wp-smiley {
	border: none;
	margin-bottom: 0;
	margin-top: 0;
	padding: 0;
}

/* Make sure embeds and iframes fit their containers. */
embed, iframe, object {
	max-width: 100%;
}

img, video {
	height: auto;
	max-width: 100%;
}

audio, video {
	display: inline-block;
}



/* ----------------------------------------------------------------------------
 * Captions
 * ------------------------------------------------------------------------- */
.wp-caption {
	max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.wp-caption .wp-caption-text {
	margin: $m-s-3 0 $m-s-6;
	@include f1-body4-2-m;
	@include r(lt) {
		margin: $d-s-3 0 $d-s-6;
		@include f1-body4-2-d;
	}
}
.wp-caption.alignleft {
	text-align: left;
}
.wp-caption.alignright {
	text-align: right;
}

/* ----------------------------------------------------------------------------
 * Galleries
 * ------------------------------------------------------------------------- */
.gallery,
.entry-content .tiled-gallery {
	margin-bottom: $m-s-6;
	@include r(lt) {
		margin-bottom: $d-s-6;
	}
}

.gallery-item {
	display: inline-block;
	text-align: center;
	vertical-align: top;
	width: 100%;
}

.gallery-columns-2 .gallery-item {
	max-width: 50%;
}

.gallery-columns-3 .gallery-item {
	max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
	max-width: 25%;
}

.gallery-columns-5 .gallery-item {
	max-width: 20%;
}

.gallery-columns-6 .gallery-item {
	max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
	max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
	max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
	max-width: 11.11%;
}

.gallery-caption {
	display: block;
}


/* ----------------------------------------------------------------------------
 * General
 * ------------------------------------------------------------------------- */

.site-content {
	padding: $m-s-10 0 0 0;
	@include r(lt) {
		padding: $d-s-10 0 0 0;
	}
}


/* ----------------------------------------------------------------------------
 * Header
 * ------------------------------------------------------------------------- */
h1.site-title,
p.site-title {
	display: block;
	margin: 24px 0 0 0;
	float: left;
	color: $dark-1;
	clear: none;
	@include f1-sitetitle-m;
	@include r(lt) {
		margin: 34px 0 0 0;
		@include f1-sitetitle-d;
	}
}

.wp-custom-logo h1.site-title,
.wp-custom-logo p.site-title {
	padding-left: $m-s-2;
	@include r(lt) {
		padding-left: $d-s-2;
	}
}

.custom-logo-link {
	margin: 12px 0 0;
	padding: 0;
	height: $m-s-7;
	display: block;
	float: left;
	@include r(lt) {
		margin: 20px 0 0;
		height: $d-s-7;
	}
}
.custom-logo-link img {
	max-height: $m-s-7;
	width: auto;
	height: auto;
	@include r(lt) {
		max-height: $d-s-7;
	}
}

#masthead {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 500;
	background-color: $light-0;
	height: $stickyheader-m;
	padding: 0 $m-s-4;
	@include shadow-mainnav;
	-webkit-transform: translateZ(0);
					transform: translateZ(0);
	transition: -webkit-transform .35s;
	transition: transform .35s;
	transition: transform .35s,-webkit-transform .35s;
	@include r(lt) {
		height: $stickyheader-d;
		padding: 0 $d-s-4;
	}
}

/* Sticky Header on Scroll Up */
#masthead.header-up {
	-webkit-transform: translate3d(0,-100%,0);
					transform: translate3d(0,-100%,0);
}

.content-wrap {
	padding-top: $stickyheader-m;
	@include r(lt) {
		padding-top: $stickyheader-d;
	}
}

.home.has-header-image .content-wrap {
	padding-top: 0;
}

.site-branding {
	display: block;
	margin: 0;
	text-align: center;
	clear: both;
	@include r(lt) {
		margin: 0;
		display: inline-block;
		float: left;
		padding: 0 $d-s-7 0 0;
		text-align: left;
	}
}

.site-branding .site-description {
	display: none;
	margin: 0;
	@include f1-body4-m;
	line-height: 1.0;
	color: $dark-1;
	float: left;
	@include r(sd) {
		display: block;
		@include f1-body4-d;
		padding: 34px 0 0 $d-s-3;
	}
}


/* ----------------------------------------------------------------------------
 * Navigation
 * ------------------------------------------------------------------------- */

/* Main Menu */

/* Hamburger Button */

#hamburger span.hamburger-title {
	display: none !important;
}

#hamburger {
	width: 24px;
	height: 24px;
	padding: 0;
	position: absolute;
	top: 20px;
	right: $m-s-4;
	z-index: 8000;
	background: none;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
	@include r(lt) {
		top: 32px;
		right: $d-s-4;
	}
}

#hamburger .lines {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 100%;
	opacity: 1.0;
	width: 24px;
	transition: .25s ease-in-out;
	transform: scale(1, 1);
	transform-origin: 50% 50%;
}

#hamburger .sublines {
	display: block;
	position: absolute;
	height: 2px;
	width: 100%;
	background: $dark-1;
	opacity: 1;
	left: 0;
	-webkit-transition: .25s ease-in-out;
	-moz-transition: .25s ease-in-out;
	-o-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
}
#hamburger .lines span:nth-child(1) {
	top: 7px;
}
#hamburger .lines span:nth-child(2) {
	top: 15px;
	left: 0;
}
#hamburger .thex {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 100%;
	opacity: 0.0;
	transition: .25s ease-in-out;
	transform: scale(0, 0);
	transform-origin: 50% 50%;
}
#hamburger .thex span:nth-child(1) {
	transform: rotate(45deg);
	top: 12px;
	background: $white;
}
#hamburger .thex span:nth-child(2) {
	transform: rotate(-45deg);
	top: 12px;
	background: $white;
}
.mobilemenu-active #nav-container #hamburger .thex {
	opacity: 1.0;
	transform: scale(1, 1);
}
.mobilemenu-active #nav-container #hamburger .lines {
	opacity: 0.0;
	transform: scale(0, 0);
}

/* Mobile Menu */
.nav-wrap {
	display: none;
	float: none;
	padding: 0;
	@include r(sd) {
		display: block;
	}
}

.nav-container .nav-wrap {
	background-color: $bg-2;
	border-width: 0;
	display: block;
	width: 100%;
	height:100vh;
	margin: 0;
	padding: $stickyheader-m $m-s-8;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	position: fixed;
	top: 0;
	bottom: 0;
	right: -100%;
	z-index: 100;
	overflow-x: visible;
	border-top: 1px solid transparent;
	box-shadow: inset 0 1px 0 rgba(255,255,255,.1);
	-webkit-overflow-scrolling: touch;
	transition: right .3s ease;
	@include r(sd) {
		max-width: 100%;
		right: auto;
		position: relative;
		width: auto;
		height: 0;
		background-color: transparent;
		padding: 0;
		overflow: visible;
		border-top: 0;
	}
}
.mobilemenu-active .nav-container .nav-wrap {
	right: 0;
}

.nav-container .nav-wrap {
	@include r(sd) {
		padding-top: 0;
	}
}

.main-navigation {
	clear: both;
	display: block;
	margin: 0;
	padding: 0 0 24px;
	@include r(sd) {
		padding: 24px 0;
		margin: 0;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	}
}

.main-navigation ul {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
}

.main-navigation ul li {
	margin: 0;
	padding: 0;
	display: block;
		position: relative;
	@include r(sd) {
		margin: 0;
		padding: 0;
	}
}
.main-navigation li a {
	display: inline-block;
	margin: 6px 8px 24px 0;
	padding: 0;
	font-size: 24px;
	line-height: 1.2;
	@include r(sd) {
		font-size: 14px;
	}
}

.main-navigation,
.main-navigation li a {
	color: $white;
}

.main-navigation .dropdown-toggle {
	display: inline-block;
	padding: 0;
	width: 40px;
	height: 40px;
	top: 0;
	position: absolute;
	background: none;
	@include r(sd) {
		display: none;
	}
}
.main-navigation .dropdown-toggle .icon {
	display: inline !important;
}

.main-navigation .sub-menu {
	display: none;
	padding-left: 24px;
	@include r(sd) {
		display: block;
		padding-left: 0;
	}
}

.main-navigation ul .sub-menu.toggled-on {
	display: block;
}

.main-navigation .sub-menu a {
	color: rgba(255, 255, 255, 0.3);
}


/* Main Content Overlay */
.mobilemenu-active {
		overflow: hidden;
		height:100%;
}

/* Nav Subelements */
.nav-subelements {
	@include r(sd) {
		float: right;
		padding: 0 0 0 $d-s-4;
	}
}

/* Social Header Menu */
.social-header-nav {
	display: block;
	margin: 0;
	padding: $m-s-6 0 0 0;
	overflow: hidden;
	@include r(sd) {
		float: left;
		padding: 33px 0 0 $d-s-4;
	}
}

.social-nav ul li a, .menu-social-container ul li a {
	color: $dark-1;
}

.social-nav ul, .menu-social-container ul {
	margin: 0;
	padding: 0;
	overflow: hidden;
}

.social-nav ul li, .menu-social-container ul li {
	display: inline-block;
	border-bottom: none;
	padding-right: $m-s-4;
	background: none;
	@include r(sd) {
		padding-right: $d-s-4;
	}
}

.social-header-nav ul li {
	padding: 0 $m-s-5 $m-s-3 0;
	@include r(sd) {
		padding-right: $d-s-4;
		padding-bottom: 0;
	}
}

.social-nav ul li:last-child, .menu-social-container ul li:last-child {
	padding-right: 0;
}

.social-nav ul li a,
 .menu-social-container ul li a {
	display: block;
	margin: 0;
	padding: 1px;
	line-height: 1;
	overflow: hidden;
	width: 22px;
	height: 22px;
}

.social-header-nav ul li a {
	width: 32px;
	height: 32px;
	@include r(sd) {
		width: 22px;
		height: 22px;
	}
}

.footer-menu-wrap .social-nav ul li a {
	width: 32px;
	height: 32px;
	@include r(lt) {
		padding-right: $d-s-4;
	}
}
.footer-menu-wrap .social-nav ul li a .icon {
	width: 30px;
	height: 30px;
}

/* Header Search */
.search-header .search-submit {
	display: none;
}
.search-header label {
	cursor: pointer;
	padding: 0;
}
.search-header .search-icon {
	width: 40px;
	height: 40px;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	text-align: left;
	margin-right: -4px;
	padding-top: 10px;
	@include r(sd) {
		text-align: center;
		padding-top: 10px;
		width: $d-s-6;
		height: $d-s-6;
	}
}

.search-header .search-icon .icon-magnifier {
	fill: rgba(255, 255, 255, .3);
	@include r(sd) {
		fill: $dark-1;
	}
}

.search-header .search-field {
	display: inline-block;
	vertical-align: middle;
	background: none;
	cursor: pointer;
	height: 40px;
	padding: 0 0 0 10px;
	border: none !important;
	width: calc(100% - 50px);
	color: $white;
	@include r(sd) {
		height: $d-s-6;
	}
}

/* Header CTA Buttons */
.header-cta-wrap {
	display: block;
	padding: $m-s-6 0 0 0;
	@include r(sd) {
		padding: 24px 0 0 40px;
		float: left;
	}
}

.header-cta-wrap ul li {
	list-style: none;
	padding: 0 0 $m-s-3;
	@include r(sd) {
		float: left;
		padding: 0 $d-s-3 0 0;
	}
}

.header-cta-wrap ul li:last-child {
	padding: 0;
}

.header-cta-wrap ul li a {
	@include btn;
	display: block;
	text-align: center;
	font-size: 13px;
	height: 64px;
	padding: 27px 16px 0;
	@include r(sd) {
		@include btn-xs;
	}
}
.header-cta-wrap ul li a .icon {
	display: none;
}


/* ----------------------------------------------------------------------------
 * Front Page Header / Hero Content
 * ------------------------------------------------------------------------- */
.custom-header {
	position: relative;
}

.has-header-image .custom-header,
.has-header-video .custom-header {
	height: 100vh;
	width: 100%;
}

.custom-header-media {
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
}

.has-header-image .custom-header-media img,
.has-header-video .custom-header-media video,
.has-header-video .custom-header-media iframe {
	position: absolute;
	height: auto;
	left: 50%;
	max-width: 1000%;
	min-height: 100%;
	min-width: 100%;
	min-width: 100vw; /* vw prevents 1px gap on left that 100% has */
	width: auto;
	top: 50%;
	-ms-transform: translateX(-50%) translateY(-50%);
	-moz-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}

/* For browsers that support 'object-fit' */
@supports ( object-fit: cover ) {
	.has-header-image .custom-header-media img,
	.has-header-video .custom-header-media video,
	.has-header-video .custom-header-media iframe {
		height: 100%;
		left: 0;
		-o-object-fit: cover;
		object-fit: cover;
		top: 0;
		-ms-transform: none;
		-moz-transform: none;
		-webkit-transform: none;
		transform: none;
		width: 100%;
	}
}

/* Hero Content Area */
.hero-container {
	position: relative;
	background: none;
	margin: $stickyheader-m 0 0 0;
	height: calc(100vh - 64px);
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	@include r(lt) {
		flex-wrap: wrap;
		justify-content: flex-start;
		margin: $stickyheader-d 0 0 0;
		height: calc(100vh - 88px);
	}
}

.hero-container:after,
.hero-container:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	display: block;
	z-index: 3;
	top: 0;
	left: 0;
	background-color: #000000;
	opacity: 0;
}

.hero-content {
	flex-grow: 1;
	flex-shrink: 0;
	max-width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 $m-s-4;
	z-index: 5;
	margin: 0 auto;
	@include r(lt) {
		padding: 0 $d-s-4;
		max-width: $website-width;
	}
	@include r(sd) {
		width: 100%;
	}
}

.hero-content-wrap {
	max-width: $content-width-nopadding;
	@include r(lt) {
		padding: 0;
	}
}

.hero-subtitle,
.product-subtitle,
.pagetitle-subtitle {
	display: block;
	@include f1-uppercase1-m;
	padding: 0 0 $m-s-5;
	@include r(lt) {
		@include f1-uppercase1-d;
		padding: 0 0 $d-s-5;
	}
}

.hero-title {
	display: block;
	@include f2-hero-m;
	padding: 0 0 $m-s-5;
	@include r(lt) {
		@include f2-hero-d;
		padding: 0 0 $d-s-5;
	}
}

.hero-text {
	@include f1-body2-m;
	margin: 0 0 $m-s-5;
	@include r(lt) {
		@include f1-body2-d;
		margin: 0 0 $d-s-5;
	}
}





/* ----------------------------------------------------------------------------
 * Blog
 * ------------------------------------------------------------------------- */

 /* ----------------------------------------------------------------------------
	* Posts
	* ------------------------------------------------------------------------- */

/* Blog Main Post Link */
.entry-link {
	display: block;
	padding-bottom: $m-s-5;
	@include transition-posthover;
	@include r(lt) {
		padding-bottom: $d-s-5;
	}
}

.entry-link:hover {
	@include shadow-m;
	@include posthover-scale-default;
}

.posts-container .hentry {
	margin-bottom: $m-s-7;
	@include r(lt) {
		margin-bottom: $d-s-7;
	}
}

.page-content, .entry-content, .entry-summary {
	margin: 0;
}

.page-links {
	clear: both;
	margin: 0;
}

/* Entry Thumbnail */
.hentry .post-thumb a {
	position: relative;
	display: inline-block;
	line-height: 0;
}

/* Entry Header */
.hentry .entry-header {
	padding: $m-s-5 10px 0;
	@include r(lt) {
		padding: $d-s-5 20px 0;
	}
}

.hentry .entry-summary {
	padding: $m-s-4 0 0;
	@include r(lt) {
		padding: $d-s-4 0 0;
	}
}

.sticky .entry-summary {
	padding: $m-s-6 0 0;
	@include r(lt) {
		padding: $d-s-6 0 0;
	}
}

/* Entry Meta Elements */
.byline, .updated:not(.published) {
	display: none;
}

.single .byline, .group-blog .byline {
	display: block;
	@include r(lt) {
		display: inline-block;
		padding: 0 3px 0 0;
	}
}

.sticky .byline {
	display: inline-block;
	padding-right: 4px;
}


/* Entry Footer */
.entry-cats a {
	display: inline;
}

.posts-container .hentry .entry-footer {
	margin-left: 10px;
	margin-right: 10px;
	padding-top: $m-s-3;
	@include r(lt) {
		margin-left: 20px;
		margin-right: 20px;
		padding-top: $d-s-3;
	}
}

.no-postcats .posts-container .hentry .entry-footer {
	padding-top: 28px;
	@include r(lt) {
		padding-top: 37px;
	}
}

.no-postcats.no-postdate.no-postcommentscount .posts-container .hentry .entry-footer {
	padding-top: 0;
	@include r(lt) {
		padding-top: 0;
	}
}

.posts-container .sticky .entry-footer {
	margin-left: 0;
	margin-right: 0;
	@include r(lt) {
		margin-left: 0;
		margin-right: 0;
		padding-bottom: $d-s-9;
	}
}

.entry-link:hover .entry-cats {
	visibility: hidden;
}

.no-postdate.no-postcommentscount .entry-link:hover .entry-cats {
	visibility: visible;
}

.entry-link .posted-on,
.entry-link .entry-comments {
	display: none;
}

.entry-link:hover .posted-on,
.entry-link:hover .entry-comments {
	display: block;
}

.entry-link .posted-on {
	position: absolute;
	bottom: 0;
	left: 0;
}

.hentry .entry-comments {
	position: absolute;
	bottom: 0;
	right: 10px;
	padding: 0;
	height: 22px;
	@include r(lt) {
		right: 0;
	}
}

.no-postcommentscount .entry-link .entry-comments {
	display: none;
}

.hentry .entry-comments .bubble-icon .icon {
	width: 18px;
	height: 18px;
	margin-top: -2px;
}

.single-post .entry-comments .bubble-icon .icon {
	top: 10px;
	left: 16px;
	width: 20px;
	height: 20px;
	@include r(lt) {
		top: 14px;
		left: 16px;
	}
}

/* Sticky Posts */
.hentry.sticky .entry-comments {
	position: relative;
	height: auto;
	right: auto;
}

.sticky .entry-footer .entry-comments:before,
.single-post .entry-meta .entry-comments:before {
	content: "\002C";
	display: inline-block;
	margin-left: -2px;
	padding-right: 2px;
}
.single-post .entry-meta .entry-comments:before {
	padding-right: 3px;
	margin-left: -3px;
}

/* Hidden Elements (via theme options settings) */
.no-postdate .entry-date, .no-postdate .posted-on, .no-postcats .entry-cats, .no-posttags .entry-tags, .no-postauthor .author, .no-postauthor .byline, .single-post.no-postauthor .author-avatar {
	display: none;
}


/* ----------------------------------------------------------------------------
 * Single Post
 * ------------------------------------------------------------------------- */
.single-post .site-main {
	padding: 0;
}

.single-post .hentry.comments-closed.no-comments {
	padding-bottom: $m-s-10;
	@include r(lt) {
		padding-bottom: $d-s-10;
	}
}

/* Single Post Header */
.single-post .entry-header,
.page-template-default .entry-header {
	position: relative;
	display: block;
	float: none;
	padding: 0 $m-s-4;
	@include r(lt) {
		padding: 0 $d-s-4;
	}
}

.single-post.sidebar-show-post .entry-header {
	width: 100%;
}

.single-post .entry-header .entry-title {
	max-width: $content-width-plus-padding;
	display: block;
	padding: 0 0 $m-s-5;
	@include r(lt) {
		padding: 0 0 $d-s-5;
	}
}

.single-post .entry-cats {
	padding: 0 0 $m-s-5;
	@include r(lt) {
		padding: 0 0 $d-s-5;
	}
}

.single-post .entry-cats a {
	padding-right: $m-s-3;
	@include r(lt) {
		padding-right: $d-s-3;
	}
}

/* Single Post Meta */
.single-post .entry-meta {
	position: relative;
	border-top: solid 1px $dark-9;
	border-bottom: solid 1px $dark-9;
	clear: both;
	padding: 12px 0;
	@include r(lt) {
		padding: $d-s-4 0;
		height: $stickyheader-d;
	}
}

.single-post .has-post-thumbnail .entry-meta {
	border-bottom: none;
}

.single-post .author .url,
.posts-container .sticky .author .url {
	font-weight: 500;
}

.single-post .author-avatar {
	display: block;
	margin: 0 $m-s-3 0 0;
	float: left;
	@include avatar-m;
	@include r(lt) {
		margin: 0 $d-s-3 0 0;
	}
}

.single-post .author-avatar img {
	@include border-radius(100px);
	@include avatar-m;
}

.single-post .author-meta-info {
	display: block;
	float: left;
	@include r(lt) {
		padding-top: 12px;
	}
}

.single-post .entry-comments {
	position: relative;
	display: inline-block;
	right: auto;
}

/* Jetpack Sharing Buttons */
#sharing-header {
	display: none;
	@include r(lt) {
		display: block;
		position: absolute;
		right: 0;
		top: 33px;
	}
}

#sharing-footer {
	display: block;
	padding: $m-s-2 0 $m-s-5;
	@include r(lt) {
		padding: $d-s-2 0 $d-s-5;
	}
}

#sharing-header .sd-social-icon .sd-content ul li[class*='share-'] a.sd-button,
#sharing-footer .sd-social-icon .sd-content ul li[class*='share-'] a.sd-button {
	background: none;
	margin-top: 0;
	background: none;
	top: 0;
	width: 16px;
	height: 16px;
	@include r(lt) {
		width: 22px;
		height: 22px;
	}
}

.jp-sharing-input-touch .sd-content ul li {
	padding-left: 0 !important;
}

#sharing-header .sd-social-icon .sd-content ul li[class*='share-'] a,
#sharing-header .sd-social-icon .sd-content ul li[class*='share-'] div.option a,
#sharing-footer .sd-social-icon .sd-content ul li[class*='share-'] a,
#sharing-footer .sd-social-icon .sd-content ul li[class*='share-'] div.option a {
	position: relative;
	display: block;
	padding: 0;
	line-height: 0;
	color: $dark-1 !important;
	border-radius: 0;
	-webkit-border-radius: 0;
}

#sharing-header .sd-social-icon .sd-content ul li[class*='share-'] a:hover {
	opacity: 1;
	color: $dark-6 !important;
}

.sharing .sd-content ul {
	margin: 0 !important;
	line-height: 0;
}

.sharing .sd-content ul li {
	margin: 0 $m-s-3 0 0;
	@include r(lt) {
		margin: 0 $d-s-4 0 0;
	}
}

.sharing .sd-content ul li:nth-last-child(2),
.sharing .sd-content ul li.share-end {
	margin: 0;
}

.sharing .sd-content ul li a.sd-button:before {
	position: absolute;
	font: normal 18px/1 'social-logos';
	line-height: 1;
	@include r(lt) {
		font: normal 24px/1 'social-logos';
	}
}

.sharing .sd-social-icon .sd-content ul li[class*='share-'] a.sd-button:before {
	top: 0;
	left: -1px;
}


/* Single Post Featured Image */
.single-post .post-thumb {
	display: block;
	padding-bottom: 0;
	padding-left: 0;
	padding-right: 0;
	float: none;
	@include r(lt) {
		padding: 0 $d-s-4;
	}
}
.single-post.sidebar-hide-post .post-thumb {
	max-width: $website-width;
}

.single-post .post-thumb img {
	display: block;
}

.single-post .entry-content {
	padding-top: $m-s-9;
	@include r(lt) {
		padding-top: $d-s-9;
	}
}

.single-post .entry-content, .single-post .entry-footer, .single-post .comments-area, .page-template-default .entry-content {
	display: block;
	float: none;
	@include push--auto;
}

.single-post.sidebar-show-post .entry-content, .single-post.sidebar-show-post .entry-footer,
.page-template-default.sidebar-show-post .entry-content {
	max-width: $content-width;
	@include r(ld) {
		width: 88.4615384%;
	}
}

/* Single Post Tags + Tagcloud Widget */
.entry-tags {
	border-bottom: solid 1px $dark-9;
	padding: 0 0 $m-s-3;
	@include r(lt) {
		padding: 0 0 $d-s-4;
	}
}

.tagcloud, .entry-tags {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.tagcloud a, .entry-tags a {
	display: block;
	margin: 0 8px 8px 0;
	padding: 10px $m-s-4;
	@include f1-btn3-m;
	@include r(lt) {
		@include f1-btn3-d;
		padding: 10px $m-s-4 9px;
	}
}

.tagcloud a {
	@include r(lt) {
		padding: 10px 8px 9px;
	}
}

/* Authorbox */
.authorbox-wrap {
	padding: $m-s-7 0 0;
	@include r(lt) {
		padding: $d-s-7 0 0;
	}
}

.authorbox-title {
	display: block;
	padding: 0 0 $m-s-2;
	@include r(lt) {
		padding: 0 0 $d-s-2;
	}
}

.authorbox-content {
	display: block;
	width: calc(100% - 88px);
	float: left;
}

.authorbox h3.author-name {
	padding: 0 0 $m-s-3;
	@include r(lt) {
		padding: 0 0 $d-s-3;
	}
}

.authorbox-content p {
	margin: 0;
}

.author-pic {
	width: 88px;
	display: block;
	float: left;
	line-height: 0;
}

.author-pic img {
	@include avatar-xl;
	@include border-radius(100px);
}

.product-content cite span .avatar {
	@include avatar-s;
	@include border-radius(100px);
}

/* Single Post - Edit Link  */
.post-edit-link {
	display: block;
	position: absolute;
	width: $d-s-5;
	height: $d-s-5;
	text-indent: -1000em;
	background: $blue;
	@include border-radius(100px);
}
.single-post .post-edit-link {
	top: 0;
	right: $m-s-4;
	@include r(lt) {
		right: $d-s-4;
	}
}

.page .post-edit-link {
	top: 0;
	right: 0;
}

.hentry .entry-footer {
	position: relative;
}

.blog .post-edit-link,
.archive .post-edit-link,
.search-results .post-edit-link {
	right: $m-s-5;
	top: $m-s-3;
	@include r(lt) {
		top: $d-s-3;
		right: $d-s-6;
	}
}

.blog .sticky .post-edit-link,
.blog .sidebar-show.sidebar-show-post .sticky .post-edit-link {
	right: 0;
	top: 0;
	bottom: auto;
	z-index: 100;
}

.blog.sidebar-hide .sticky .post-edit-link {
	@include r(lt) {
		top: $d-s-3;
		right: $d-s-3;
	}
}

.post-edit-link .icon {
	position: absolute;
	top: $d-s-2;
	left: $d-s-2;
	width: 16px;
	height: 16px;
	fill: $white;
}


/* Single Post - Related Posts  */
.related-wrap {
	margin: $m-s-10 $m-s-4 0;
	background: $dark-9;
	@include r(lt) {
		margin: $d-s-10 $d-s-4 0;
	}
}

.sidebar-hide-post .related-wrap {
	margin-left: 0;
	margin-right: 0;
}

.related {
	padding: $m-s-9 0 $m-s-5;
	@include r(lt) {
		padding: $d-s-9 0 $d-s-5;
	}
}

.sidebar-show-post .related {
	padding-left: $m-s-4;
	padding-right: $m-s-4;
	@include r(lt) {
	padding-left: $d-s-4;
	padding-right: $d-s-4;
	}
}

h2.section-title {
	letter-spacing: 0;
	padding: 0 0 $m-s-5;
	@include r(lt) {
	padding: 0 0 $d-s-5;
	}
}

.related-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.related-post {
	padding: 0 $m-s-4 $m-s-5;
	text-align: center;
	@include r(lt) {
	padding: 0 $d-s-4 $d-s-5;
	float: left;
	}
}

.related-link {
	position: relative;
	overflow: hidden;
	display: block;
	@include transition-standard;
}

.related-link:hover {
	background: $white;
	@include shadow-s;
}

.related-thumb {
	line-height: 0;
}

.related-entry-cats {
	padding: $m-s-5 $m-s-5 $m-s-2;
	@include r(lt) {
		padding: $d-s-5 $d-s-5 $d-s-2;
	}
}

h3.related-title {
	letter-spacing: 0;
	padding: 0 $m-s-5 $m-s-5;
	@include r(lt) {
		padding: 0 $d-s-5 $d-s-5;
	}
}

/* ----------------------------------------------------------------------------
 * Comments
 * ------------------------------------------------------------------------- */
.comments-area {
	padding: $m-s-10 $m-s-4;
	clear: both;
	@include r(lt) {
		padding: $d-s-10 $d-s-4;
	}
}
@include r(lt) {
.sidebar-show-post .comments-area  {
	padding-left: $d-s-4;
	padding-right: $d-s-4;
}
}
.comments-area h2.comments-title {
	text-align: center;
	display: block;
}

.comment-list {
	list-style: none;
	padding: $m-s-3 0 0;
	@include r(lt) {
		padding: $d-s-3 0 0;
	}
}

.comment-content a {
	word-wrap: break-word;
}

.comment-content p {
	margin: 0 0 $m-s-4;
	@include r(lt) {
		margin: 0 0 $d-s-4;
	}
}

.comment-content p:last-child {
	margin: 0;
}

.bypostauthor {
	display: block;
}

.says {
	display: none;
}

.comment-list b.fn {
	font-weight: normal;
}


.comment-meta {
	display: block;
	padding: 0 0 $m-s-3;
	@include r(lt) {
		padding: 0 0 $d-s-3;
	}
}

.comment-author {
	display: inline;
}

.comment-author .avatar {
	@include border-radius(100px);
	@include avatar-s;
	margin: 0 $m-s-3 0 0;
	float: left;
	@include r(lt) {
		margin: 0 $d-s-3 0 0;
	}
}

.comment-body p.comment-awaiting-moderation {
	margin: $m-s-1 0 0 0;
	display: inline-block;
	background: #fff882;
	@include r(lt) {
		margin: $d-s-1 0 0 0;
	}
}

.comment-body {
	position: relative;
	margin: $m-s-4 0 0;
	padding: $m-s-4 $m-s-6 $m-s-8;
	/* 48px minus border */
	border: solid 1px $dark-9;
	@include r(lt) {
		margin: $d-s-4 0 0;
		padding: $d-s-4 $d-s-7 64px;
	}
}

.comment-list .children {
	margin-left: 0;
	padding: 0;
	list-style: none;
}

.comment-list .children .comment-body {
	margin: -1px 0 0 0;
}

.comment-body .reply {
	position: absolute;
	bottom: $m-s-4;
	right: $m-s-7;
	@include r(lt) {
		bottom: $d-s-4;
		right: $d-s-7;
	}
}

.comment-body .reply a {
	position: relative;
	display: block;
	height: $m-s-5;
	font-size: 13px;
	line-height: 1;
	color: $dark-6;
	background: transparent;
	padding: 5px $m-s-2 0 26px;
@include r(lt) {
		height: $d-s-5;
		padding: 10px $d-s-2 0 30px;
	}
}

.comment-body .reply a:hover {
	color: $dark-3;
}

.reply .icon-reply {
	fill: $dark-6;
	width: 16px;
	height: 16px;
	position: absolute;
	top: $m-s-2;
	left: $m-s-2;
	@include r(lt) {
		top: $d-s-2;
		left: $d-s-2;
	}
}

.comments-area .no-comments {
	margin: $m-s-6 0 0 0;
	@include r(lt) {
		margin: $d-s-6 0 0 0;
	}
}

/* Comments Reply */
.comment-respond {
	position: relative;
	margin: $m-s-4 0 0;
	padding: $m-s-6;
	background: $dark-9;
	@include r(lt) {
		margin: $d-s-4 0 0;
		padding: $d-s-7;
	}
}

.comment-list .parent .comment-respond {
	margin: 0;
}

.comment-reply-title {
	position: relative;
}

.comment-reply-title #cancel-comment-reply-link {
	position: absolute;
	top: 0;
	right: 0;
}

.comment-respond .form-submit {
	margin: 0;
}

.comment-notes,
.logged-in-as {
	margin: 0;
	padding: $m-s-2 0 $m-s-4;
	@include f1-body5-2-m;
	@include r(lt) {
		padding: $d-s-2 0 $d-s-4;
		@include f1-body5-2-d;
	}
}

.comment-form-author, .comment-form-email, .comment-form-url {
	margin: $m-s-4 0 0 0;
	@include r(lt) {
		width: 32.5581395%;
		margin-top: $d-s-4;
		margin-right: 1.16279075%;
		float: left;
	}
}

.comment-form-url {
	clear: right;
	margin-right: 0;
}

.form-submit {
	display: block;
	clear: both;
	padding: $m-s-4 0 0 0;
	@include r(lt) {
		padding: $d-s-4 0 0 0;
	}
}
.comment-metadata .edit-link {
	padding: 0 0 0 8px;
}


/* ----------------------------------------------------------------------------
 * Blog Archives
 * ------------------------------------------------------------------------- */
.archive .page-header, .search .page-header {
	padding: 0 $m-s-4 $m-s-3;
	@include r(lt) {
		padding: 0 $d-s-4 $d-s-3;
	}
}

.archive .page-header p, .search .page-header p {
	margin: $m-s-1 0 0 0;
	@include r(lt) {
		margin: $d-s-1 0 0 0;
	}
}




/* ----------------------------------------------------------------------------
 * Pages
 * ------------------------------------------------------------------------- */

.single-page .type-page .entry-header,
.error-404 .entry-header {
	position: relative;
	margin: 0 $m-s-4 $m-s-9;
	padding: 0 0 $m-s-5;
	border-bottom: solid 1px $dark-9;
	@include r(lt) {
		margin: 0 $d-s-4 $d-s-9;
		padding: 0 0 $d-s-5;
	}
}

.archive .type-page .entry-header,
.search-results .type-page .entry-header {
	padding-top: $m-s-5;
	padding-bottom: 0;
	@include r(lt) {
		padding-top: $d-s-5;
		padding-bottom: 0;
	}
}

.archive .type-page .entry-footer,
.search-results .type-page .entry-footer {
	display: none;
}

.type-page .entry-content,
.error-404 .entry-content {
	padding-bottom: $m-s-8;
	@include r(lt) {
		padding-bottom: $d-s-8;
	}
}

.type-page.comments-open .entry-content,
 .type-page.comments-closed.has-comments .entry-content {
	padding-bottom: 0;
	@include r(lt) {
		padding-bottom: 0;
	}
}

.type-page.comments-open .entry-content p:last-child,
.type-page.comments-closed.has-comments .entry-content p:last-child {
	margin-bottom: 0;
	padding-bottom: 0;
}

/* Pages without showing the title */
.no-pagetitle .type-page .entry-header .entry-title {
	display: none;
}
.no-pagetitle .type-page .entry-header {
	margin-bottom: 0;
	padding-bottom: 0;
	border-bottom: none;
}

/* Fullscreen Page (for Page Building) */
.fullscreen-page .type-page .entry-header {
	display: none;
}
.fullscreen-page .site-content {
	max-width: none;
	padding: 0;
}

.fullscreen-page .site-content .type-page {
	clear: both;
	overflow: hidden;
}

.fullscreen-page .site-content .entry-content {
	padding: 0;
	float: none;
}


 /* ----------------------------------------------------------------------------
	* Paginations
	* ------------------------------------------------------------------------- */
.site-main .navigation, .site-main .post-navigation {
	margin: 0;
	padding-left: $m-s-4;
	padding-right: $m-s-4;
	@include f1-btn2-m;
	@include r(lt) {
		@include f1-btn2-d;
		padding-left: $d-s-4;
		padding-right: $d-s-4;
	}
}

.blog .site-main .navigation, .archive .site-main .navigation, .search-results .site-main .navigation {
	padding-bottom: $m-s-9;
	@include r(lt) {
		padding-bottom: $d-s-9;
	}
}

.blog .site-main .navigation .nav-links, .archive .site-main .navigation .nav-links, .search-results .site-main .navigation .nav-links {
	border-top: 1px solid $dark-9;
	padding-top:  $m-s-9;
	@include r(lt) {
		padding-top:  $d-s-9;
	}
}

.site-main .comment-navigation {
	padding: 16px 0 0 0;
}

.comment-navigation .nav-links {
	display: block;
	border-top: solid 1px $dark-9;
	padding: $m-s-4 0 $m-s-5;
	clear: both;
	@include r(lt) {
		padding: $d-s-4 0 $d-s-5;
	}
}

.comment-navigation .nav-previous, .navigation .prev, .post-navigation .nav-previous {
	float: left;
	margin: 0;
	clear: left;
}

.comment-navigation .nav-next, .navigation .next, .post-navigation .nav-next {
	float: right;
	text-align: right;
	margin: 0;
	clear: right;
}

.nav-links {
	text-align: center;
}

.navigation a, .navigation .current, .post-navigation a {
	display: inline-block;
	margin: 0 3px;
	padding: 18px 21px;
	background: transparent;
	line-height: 1;
	@include f1-btn2-m;
	@include r(lt) {
		margin: 0 $d-s-1;
		padding: 21px 24px;
		@include f1-btn2-d;
	}
}

.comment-navigation a {
	margin: 0;
}

.blog .site-main .navigation .prev,
.blog .site-main .navigation .next,
.archive .site-main .navigation .prev,
.archive .site-main .navigation .next,
.search-results .site-main .navigation .prev,
.search-results .site-main .navigation .next {
	position: relative;
	display: none;
	width: $m-s-8;
	height: $m-s-8;
	@include r(lt) {
		display: block;
		width: $d-s-8;
		height: $d-s-8;
	}
}

.blog .site-main .navigation .meta-nav,
.archive .site-main .navigation .meta-nav,
.search-results .site-main .navigation .meta-nav {
	display: none;
}

/* Pagination - Single Post */
.single-post .post-navigation {
	margin: 0 $m-s-4;
	padding: $m-s-9 0 $m-s-3;
	border-top: 1px solid $dark-9;
	@include r(lt) {
		margin: 0 $d-s-4;
		padding: $d-s-9 0 $d-s-10;
	}
}

.single-post.sidebar-hide-post .post-navigation {
	max-width: $content-width-plus-padding;
	margin: 0 auto;
}

.single-post .nav-links {
	text-align: left;
	padding: 0 $m-s-4;
	@include r(lt) {
		padding: 0 $d-s-4;
	}
}

.single-post.sidebar-hide-post .nav-links {
	padding: 0;
}

.single-post.sidebar-show-post .nav-links {
	padding: 0;
}

.single-post .navigation a {
	margin: 0;
	padding: 0;
	text-align: left;
	background: none;
}

.single-post .navigation a span.nav-subtitle {
	display: block;
	padding: $m-s-4 0 0 0;
	@include r(lt) {
		padding: $d-s-4 0 0 0;
	}
}

.single-post .navigation .nav-thumb {
	float: left;
	line-height: 0;
}
.single-post.sidebar-show-post .navigation .nav-thumb {
	float: none;
}

.single-post .navigation .nav-title {
	display: block;
	float: left;
}

.single-post .navigation .nav-title {
	display: block;
	padding: $m-s-5 10px;
	@include r(lt) {
		padding: $d-s-5 20px;
	}
}
.single-post .navigation .next, .post-navigation .nav-next,
.single-post .navigation .prev, .post-navigation .nav-previous {
		padding: 0 0 $m-s-6;
		@include r(lt) {
			padding: 0;
			background: $white;
			width: 46.9387755%;
		}
}



/* ----------------------------------------------------------------------------
 * Infinite Scroll
 * ------------------------------------------------------------------------- */
/* Globally hidden elements when Infinite Scroll is supported and in use. */
.infinite-scroll .posts-navigation, .infinite-scroll.neverending .site-footer {
	/* Theme Footer (when set to scrolling) */
	display: none;
}

/* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before. */
.infinity-end.neverending .site-footer {
	display: block;
}


/* ----------------------------------------------------------------------------
 * Widget Areas
 * ------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------
* Widget Areas - Header
* ------------------------------------------------------------------------- */
.header-menu-widget-area {
	display: block;
	padding: $m-s-3 0 0 0;
	@include r(lt) {
		padding: $d-s-3 0 0 0;
	}
}

.header-menu-widget-area .textwidget p {
	margin: 0;
}


/* ----------------------------------------------------------------------------
 * Blog Sidebar + General Content Widths
 * ------------------------------------------------------------------------- */
#secondary {
	padding-bottom: $m-s-4;
	max-width: $content-width;
	@include push--auto;
	@include r(lt) {
		padding-bottom: $d-s-4;
	}
}

.blog.sidebar-show .content-area, .archive.sidebar-show .content-area, .search.sidebar-show .content-area, .single-post.sidebar-show-post .content-area, .page-template-default .content-area {
	@include r(sd) {
		width: 66.667%;
	}
}

.nosidebar-page .content-area,
.nosidebar-notitle-page .content-area,
.error404 .content-area {
	@include r(sd) {
		width: 66.667%;
		@include push--auto;
	}
}

.blog.sidebar-show .content-area, .archive.sidebar-show .content-area, .search.sidebar-show .content-area, .single-post.sidebar-show-post .content-area, .page-template-default .content-area {
	@include r(sd) {
		float: left;
	}
}

.blog.sidebar-show #secondary, .archive.sidebar-show #secondary, .search.sidebar-show #secondary, .single-post.sidebar-show-post #secondary, .page-template-default #secondary {
	@include r(sd) {
		width: 33.333%;
		float: right;
	}
}

/* Blog with 3 column grid and sidebar */
.blog.sidebar-show.blog-grid-small .content-area {
	@include r(sd) {
		width: 75%;
	}
}

.blog.sidebar-show.blog-grid-small #secondary {
	@include r(sd) {
		width: 25%;
	}
}





/* ----------------------------------------------------------------------------
 * Footer Widgets
 * ------------------------------------------------------------------------- */
.footer-widget-wrap {
	padding-bottom: $m-s-4;
	@include r(lt) {
		padding-bottom: $d-s-4;
	}
}
.footer-widget .widget {
	padding: 0 0 $m-s-7;
	@include r(lt) {
		padding: 0 0 $d-s-7;
	}
}

.site-info ul li {
	display: inline-block;
}

.site-info ul li:last-child {
	padding: 0 0 0 16px;
}

/* ----------------------------------------------------------------------------
 * Default Widgets
 * ------------------------------------------------------------------------- */

 .widget-area-default .widget {
	 padding: 0 $m-s-4 $m-s-7;
	 @include r(lt) {
		 padding: 0 $d-s-4 $d-s-7;
	 }
 }


 .widget-area-default h2.widget-title {
	 padding: 0 0 $m-s-3;
	 @include r(lt) {
		padding: 0 0 $d-s-3;
	 }
 }


/* Make sure select elements fit in widgets. */
.widget select {
	max-width: 100%;
}

.textwidget p:last-child {
	margin-bottom: 0;
}

.widget ul li {
	padding: 0 0 $m-s-3;
	@include r(lt) {
		padding: 0 0 $d-s-3;
	}
}

.widget ul li:last-child {
	padding: 0;
}

/* Search Widget */
.widget_search .search-form,
.entry-content .search-form {
	position: relative;
	display: block;
	overflow: hidden;
}
.widget_search form label,
.entry-content form label {
		padding: 0;
}
.widget_search input[type="search"],
.entry-content input[type="search"] {
	background: transparent;
	border: solid 1px $dark-8;
	outline: none;
	padding: 0 0 0 $d-s-8;
	height: $d-s-8;
	line-height: 1;
	float: left;
	width: 100%;
	box-sizing: border-box;
	@include transition-standard;
}

.widget_search input[type="search"]:focus,
.entry-content input[type="search"]:focus {
	border-color: $blue;
}

.widget_search .search-icon,
.entry-content .search-icon {
	position: absolute;
	top: 0;
	left: 0;
	width: $d-s-8;
	height: $d-s-8;
	text-align: center;
	padding: 17px 0 0 0;
}

.widget_search .search-icon .icon-magnifier,
.entry-content .search-icon .icon-magnifier {
	fill: $dark-6;
}

.search-submit {
	display: none;
}

/* Search form with less height, if sidebar is narrow */
.blog.sidebar-show.blog-grid-small .widget_search input[type="search"] {
	height: 56px;
}

.blog.sidebar-show.blog-grid-small .widget_search .search-submit, .blog.sidebar-show.blog-grid-small .widget_search .search-submit:before {
	width: 56px;
	height: 56px;
}

.blog.sidebar-show.blog-grid-small .widget_search .search-submit:before {
	padding: 19px 0 0 0;
}

/* Archive + Category + Pages + Nav Menu Widget */
.widget_categories select, .widget_archive select {
	display: block;
	width: 100%;
	height: 34px;
	padding: 6px 12px;
	line-height: 1.42857143;
	background-color: #fafafa;
	background-image: none;
	border: 1px solid $dark-9;
	border-color: $dark-9;
	@include border-radius(0);
	box-shadow: none;
}

.widget-area-default .widget_categories ul.children,
.widget-area-default .widget_pages ul.children,
.widget-area-default .widget_nav_menu .sub-menu {
	padding: $m-s-3 0 0 $m-s-4;
	@include r(lt) {
		padding: $d-s-3 0 0 $d-s-4;
	}
}

/* Calendar Widget */
#wp-calendar caption {
	font-weight: bold;
}

.widget_calendar th {
	text-align: center;
}

.widget_calendar tfoot td {
	border: 0;
}


/* ----------------------------------------------------------------------------
 * Special Styled Widgets (on a Background)
 * ------------------------------------------------------------------------- */


.widget-area-default .null-instagram-feed,
.widget-area-default .widget_mc4wp_form_widget {
 background-color: $dark-9;
 text-align: center;
 margin: 0 $m-s-4 $m-s-6;
 padding: $m-s-7;
 @include r(lt) {
	 margin: 0 $d-s-4 $d-s-7;
	 padding: $d-s-7;
 }
}

.widget-area-default .null-instagram-feed:last-of-type,
.widget-area-default .widget_mc4wp_form_widget:last-of-type {
 padding: $m-s-8 $m-s-7;
 @include r(lt) {
	 padding: $d-s-8 $d-s-7;
 }
}

.widget-area-default .null-instagram-feed h2.widget-title,
.widget-area-default .widget_mc4wp_form_widget h2.widget-title {
 color: $dark-3;
 padding: 0 0 $m-s-5;
 @include r(lt) {
	 padding: 0 0 $d-s-5;
 }
}

.blog-grid-small .widget-area-default .null-instagram-feed,
.blog-grid-small .widget-area-default .widget_mc4wp_form_widget {
 padding: $m-s-8 $m-s-5;
 @include r(lt) {
	 padding: $d-s-8 $d-s-5;
 }
}

.footer-wrap .widget-area-default .null-instagram-feed,
.footer-wrap .widget-area-default .widget_mc4wp_form_widget {
	background-color: $light-9;
}

.footer-wrap .widget-area-default .null-instagram-feed h2.widget-title,
.footer-wrap .widget-area-default .widget_mc4wp_form_widget h2.widget-title {
	color: $light-6;
}



/* ----------------------------------------------------------------------------
 * Widgets via external plugins
 * ------------------------------------------------------------------------- */

/* WP Instagram Widget */
.instagram-pics {
	display: block;
	float: none;
	overflow: hidden;
}

.widget .instagram-pics li {
	width: 48.5294117%;
	float: left;
	display: block;
	margin: 0 2.94117647% 8px 0;
	padding: 0;
}

.widget ul.instagram-pics li:nth-child(2n+2) {
	margin: 0 0 $m-s-3 0;
}

.widget .instagram-pics li a {
	display: block;
	line-height: 0;
}

.null-instagram-feed p.clear {
	margin: 0;
}

.null-instagram-feed p.clear a {
	margin-top: $m-s-3;
	display: inline-block;
	@include f1-btn3-2-m;
	padding: 8px $m-s-3;
	height: $m-s-6;
	@include r(lt) {
		padding: 13px $d-s-3;
		height: $d-s-6;
		@include f1-btn3-2-d;
	}
}

/* Mailchimp for WordPress Newsletter Widget */
.widget-area-default .widget_mc4wp_form_widget input {
	border: none;
	text-align: center;
	margin: 0 0 12px;
}

.site-footer .widget-area-default .widget_mc4wp_form_widget input[type="email"] {
	background-color: $light-8;
	color: $light-5;
	@include input-placeholder {
		color: $light-5;
	}
}

.site-footer .widget-area-default .widget_mc4wp_form_widget input[type="email"]:focus {
	background-color: $light-7;
	color: $white;
	@include shadow-formsfocus;
}

.widget_mc4wp_form_widget input[type="submit"] {
	display: block;
	width: 100%;
	margin: 0;
}


/* ----------------------------------------------------------------------------
 * Custom Theme Widgets
 * ------------------------------------------------------------------------- */

/* Page Builder Customizations */
.tailor-ui .tailor-section,
.tailor-ui .tailor-widget,
.tailor-ui .tailor-grid,
.tailor-ui .tailor-element {
	margin-bottom: 0;
}

.tailor-ui .tailor-box {
	padding: 0;
	border: none;
}

.tailor-ui .tailor-grid,
.entry-content .teammember-wrap {
	width: 100%;
	padding: $m-s-10 0 !important;
	@include r(lt) {
		padding: $d-s-10 0 !important;
	}
}

.tailor-ui .tailor-grid.short-block,
.entry-content .pageblock {
	padding: $m-s-10 0;
	@include r(lt) {
		padding: $d-s-10 0;
	}
}

.tailor-ui .tailor-grid__item,
.tailor-ui .tailor-grid--mobile .tailor-grid__item,
.tailor-ui .tailor-grid--tablet .tailor-grid__item,
.tailor-ui .tailor-grid--desktop .tailor-grid__item,
.entry-content .elementor-col-25,
.entry-content .elementor-col-33 {
	border: none !important;
	padding-left: $m-s-4;
	padding-right: $m-s-4;
		@include r(lt) {
			padding-left: $d-s-4;
			padding-right: $d-s-4;
		}
}

.tailor-ui .tailor-grid--desktop .tailor-grid__item,
.entry-content .elementor-col-25,
.entry-content .elementor-col-33 {
	padding-bottom: $m-s-5;
	@include r(lt) {
		padding-bottom: 0;
	}
}

.tailor-ui .tailor-grid--desktop .tailor-grid__item:last-child {
	padding-bottom: 0;
}

.emptybg {
	min-height: 300px !important;
}

.site-content .elementor-column-gap-default>.elementor-row>.elementor-column>.elementor-element-populated {
		padding: 0;
}
.site-content .elementor-section.elementor-section-boxed>.elementor-container {
	max-width: 100%;
}

.entry-content .elementor-widget:not(:last-child) {
	margin-bottom: 0;
}

/* Custom class for theme width items inside pagebuilder elements */
.hidden,
.hide {
	visibility: hidden !important;
}

.theme-width,
.themewidth {
	 max-width: $website-width;
	 @include push--auto;
	 @include r(d) {
		 width: $website-width;
	 }
}

.tencol-width {
	@include push--auto;
	max-width: 992px;
}


/* ----------------------------------------------------------------------------
 * Button
 * ------------------------------------------------------------------------- */

.button {
	@include f1-btn2-d;
	display: inline-block;
	text-align: center;
	color: $white;
	background-color: $dark-1;
}

/* Button Sizes */
.button-xl {
	padding: 26px 62px;
}

.button-l {
	padding: 22px 50px;
}

.button-m {
	padding: 18px 38px;
}

.button-s {
	padding: 10px 20px 12px;
}

.button-xs {
	padding: 11px 20px;
}

.nav-container.toggled .button-s {
	padding: 19px 20px 21px;
	width: 272px;
}

/* Button Borders */
.button-border {
	border: 1px solid $dark-1;
}

.nav-container.toggled .button-border {
	border: 1px solid $white;
}


/* ----------------------------------------------------------------------------
 * Section Title
 * ------------------------------------------------------------------------- */
.pukeko_sectiontitle,
.elementor-widget-wp-widget-pukeko-sectiontitle-widget {
	position: relative;
	max-width: $website-width;
	@include push--auto;
	margin-top: $m-s-10;
	margin-bottom: $m-s-3;
	padding: 0 $m-s-4;
	@include r(lt) {
		margin-top: $d-s-10;
		margin-bottom: $d-s-3;
		padding: 0 $d-s-4;
	}
}

.pukeko_sectiontitle .section-title,
.elementor-widget-wp-widget-pukeko-sectiontitle-widget .section-title {
	display: block;
	max-width: $col5-nopadding;
	margin-bottom: 0;
	padding: 0 0 $d-s-3;
	text-align: left
}

.pukeko_sectiontitle p,
.elementor-widget-wp-widget-pukeko-sectiontitle-widget p,
.pukeko_pagetitle p,
.elementor-widget-wp-widget-pukeko-pagetitle-widget p {
	@include f1-body3-m;
	margin: 0;
	max-width: $col5-nopadding;
	@include r(lt) {
		@include f1-body3-d;
	}
}

a.sectiontitle-btn {
	margin-top: $d-s-3;
}

.pukeko_sectiontitle.left a.sectiontitle-btn,
.elementor-widget-wp-widget-pukeko-sectiontitle-widget .left a.sectiontitle-btn {
	@include r(lt) {
		position: absolute;
		right: 24px;
		top: 0;
		margin-top: 0;
	}
}

.pukeko_sectiontitle.center,
.pukeko_sectiontitle.center .section-title,
.pukeko_sectiontitle.center p,
.elementor-widget-wp-widget-pukeko-sectiontitle-widget .center,
.elementor-widget-wp-widget-pukeko-sectiontitle-widget .center .section-title,
.elementor-widget-wp-widget-pukeko-sectiontitle-widget .center p {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

/* ----------------------------------------------------------------------------
 * Page Title
 * ------------------------------------------------------------------------- */
.tailor-element .pukeko_pagetitle,
.elementor-widget-wrap .elementor-widget-wp-widget-pukeko-pagetitle-widget {
 max-width: $col7;
 @include col-padding;
}

.elementor-widget-wp-widget-pukeko-pagetitle-widget.tencol-width {
	 @include push--auto;
 }

.tailor-column .pukeko_pagetitle,
.elementor-column-wrap .elementor-widget-wp-widget-pukeko-pagetitle-widget .pukeko-pagetitle-wrap.center {
	@include push--auto;
	max-width: $col7;
	@include col-padding;
}

.pukeko_pagetitle,
.elementor-widget-wp-widget-pukeko-pagetitle-widget {
	padding: $m-s-10 0 $m-s-3;
	@include r(lt) {
		padding: $d-s-10 0 $d-s-3;
	}
}

.pukeko_pagetitle .section-title,
.pukeko_pagetitle p,
.elementor-widget-wp-widget-pukeko-pagetitle-widget .section-title {
	margin: 0;
	padding: 0 0 $m-s-5;
	@include r(lt) {
		padding: 0 0 $d-s-5;
	}
}

.pukeko_pagetitle.center p,
.elementor-widget-wp-widget-pukeko-pagetitle-widget .center p {
	margin: 0 auto !important;
}


/* ----------------------------------------------------------------------------
 * Contact Form 7 in Page Section
 * ------------------------------------------------------------------------- */
 .tailor-section .wpcf7,
 .elementor-widget-container .wpcf7 {
	 padding: $m-s-10 0;
	 @include r(lt) {
		 padding: $d-s-10 0;
	 }
 }

 .tailor-column .wpcf7,
.elementor-widget-container .wpcf7 {
	 max-width: $col7;
	 @include col-padding;
	 @include push--auto;
 }

.wpcf7 .wpcf7-form-control-wrap {
	display: block;
	margin-top: $m-s-2;
	@include r(lt) {
		margin-top: $d-s-2;
	}
}


/* ----------------------------------------------------------------------------
 * Product
 * ------------------------------------------------------------------------- */
.pukeko_product,
.elementor-widget-wp-widget-pukeko-product-widget {
	padding: $m-s-10 $m-s-4;
	@include push--auto;
	@include r(lt) {
		display: flex;
		flex-direction: row;
		padding: $d-s-10 $d-s-4;
	}
}

.pukeko_product,
.elementor-widget-wp-widget-pukeko-product-widget.is-fullwidth {
	max-width: $website-width;
}

.elementor-widget-wp-widget-pukeko-product-widget .pukeko-product-wrap {
	@include r(lt) {
		display: flex;
		flex-direction: row;
	}
}

.pukeko_product.fullscreen,
.is-fullscreen.elementor-widget-wp-widget-pukeko-product-widget {
	max-width: 100%;
	padding: $m-s-10 0;
	@include r(lt) {
		padding: $d-s-10 0;
	}
	@include r(ld) {
		max-width: 2000px;
	}
}

.is-fullscreen.elementor-widget-wp-widget-pukeko-product-widget {
	width: 100%;
}

.pukeko_product.product-right,
.elementor-widget-wp-widget-pukeko-product-widget .pukeko-product-wrap.right {
	flex-direction: row-reverse;
}

.product-img-wrap {
	@include r(lt) {
		width: 50%;
	}
}

.is-fullscreen .right .product-img-wrap img {
	width: 100%;
}

.product-content-wrap {
	text-align: left;
	justify-content: flex-end;
	align-items: center;
	display: flex;
	@include r(lt) {
		width: 50%;
	}
}

.pukeko_product.fullscreen .product-content-wrap,
.elementor-widget-wp-widget-pukeko-product-widget .fullscreen .product-content-wrap {
	@include r(lt) {
		max-width: 624px;
		padding-right: $d-s-4;
	}
}

.pukeko_product.product-right .product-content-wrap,
.elementor-widget-wp-widget-pukeko-product-widget .right .product-content-wrap {
	justify-content: flex-start;
}

.pukeko_product.fullscreen.product-right .product-content-wrap,
.elementor-widget-wp-widget-pukeko-product-widget .fullscreen.right .product-content-wrap .product-content-wrap {
	@include r(lt) {
		max-width: 624px;
		padding-left: $d-s-4;
		padding-right: 0;
	}
}

.pukeko_product.fullscreen .product-content,
.elementor-widget-wp-widget-pukeko-product-widget .fullscreen .product-content-wrap {
	padding: $m-s-5 $m-s-4 0;
	@include r(sd) {
		padding: $m-s-5 0 0;
	}
}

.product-content {
	padding: $m-s-5 0 0 0;
	@include r(lt) {
		padding: 0 0 0 $d-s-4;
	}
	@include r(d) {
		width: 78.666%;
		padding: 0;
	}
}

.product-right .product-content {
	@include r(lt) {
		padding: 0 $d-s-4 0 0;
	}
	@include r(d) {
		padding: 0;
	}
}

.product-content .section-title {
	margin-bottom: 0;
}
.product-content p {
	@include f1-body3-m;
	margin: 0;
	@include r(lt) {
		@include f1-body3-d;
	}
}

.product-content .product-btn {
	margin-top: $m-s-5;
	@include r(lt) {
		margin-top: $d-s-5;
	}
}

.product-content blockquote {
	@include f1-body4-m;
	padding: $m-s-5 0 0 0;
	border: none;
	@include r(lt) {
		@include f1-body4-d;
		padding: $d-s-5 0 0 0;
	}
}

.product-content blockquote p,
.product-content blockquote cite {
	@include f1-body4-m;
	@include r(lt) {
		@include f1-body4-d;
	}
}

.product-content blockquote p {
	font-style: italic;
}

.product-content blockquote p:before,
.product-content blockquote p:after {
	display: inline;
	content: '\0022';
}

.product-content blockquote cite {
	padding: $m-s-2 0 0 0;
	color: $dark-3;
	@include r(lt) {
		padding: $d-s-2 0 0 0;
	}
}

.product-content blockquote cite span {
	padding: 0 $m-s-2 0 0;
	@include r(lt) {
		padding: 0 $d-s-2 0 0;
	}
}


/* ----------------------------------------------------------------------------
 * Team Member
 * ------------------------------------------------------------------------- */
.pukeko_teammember,
.elementor-col-100 .elementor-widget-wp-widget-pukeko-teammember-widget {
	margin: $m-s-10 auto;
	@include r(lt) {
		margin: $d-s-10 auto;
	}
}

.tailor-grid__item .tailor-widget,
.elementor-widget-wp-widget-pukeko-teammember-widget {
	margin-bottom: $m-s-6;
	@include r(lt) {
		margin-bottom: 0;
	}
}

.tailor-grid__item .pukeko_teammember,
.elementor-widget-wp-widget-pukeko-teammember-widget {
	margin: 0 auto;
	padding: 0;
}

.teammember-content-wrap h2.section-title {
 margin: 0;
 padding: 0 0 $m-s-3;
 @include r(lt) {
	 padding: 0 0 $d-s-3;
 }
}

.elementor-col-100 .elementor-widget-wp-widget-pukeko-teammember-widget .teammember-content-wrap h2.section-title {
	text-align: left;
}

.teammember-content-wrap h2.section-title {
	text-align: center;
}

.teammember-content-wrap h2.section-title span,
.elementor-col-100 .teammember-content-wrap h2.section-title span {
	display: block;
	padding: $m-s-2 0 0 0;
	@include r(lt) {
		padding: $d-s-2 0 0 0;
	}
}

.tailor-grid__item .teammember-content-wrap h2.section-title span,
.elementor-widget-wp-widget-pukeko-teammember-widget .teammember-content-wrap h2.section-title span {
	display: block;
	padding: $m-s-1 0 0 0;
	@include r(lt) {
		padding: $d-s-1 0 0 0;
	}
}

.teammember-img-wrap {
	margin: 0 auto;
	text-align: center;
}

.teammember-img,
.elementor-col-100 .elementor-widget-wp-widget-pukeko-teammember-widget .teammember-img {
	width: 100%;
	max-width: $ten-columns-width;
	padding: 0 0 $m-s-8;
	@include r(lt) {
		padding: 0 0 $d-s-8;
	}
}

.tailor-grid__item .teammember-img,
.elementor-widget-wp-widget-pukeko-teammember-widget .teammember-img {
	padding: 0 0 $m-s-5;
	@include r(lt) {
		padding: 0 0 $d-s-5;
	}
}

.teammember-content-wrap,
.elementor-col-100 .elementor-widget-wp-widget-pukeko-teammember-widget .teammember-content-wrap {
	position: relative;
	padding: 0 $m-s-4 $m-s-8;
	max-width: $content-width-plus-padding;
	margin: 0 auto;
	@include r(lt) {
		padding: 0 $d-s-4 $d-s-8;
	}
}

.tailor-grid__item .teammember-content-wrap,
.elementor-widget-wp-widget-pukeko-teammember-widget .teammember-content-wrap {
	padding: 0 10px $m-s-5;
	@include r(lt) {
		padding: 0 20px $d-s-5;
	}
}

.tailor-grid__item .teammember-content-wrap p,
.tailor-grid__item .teammember-social-wrap,
.elementor-widget-wp-widget-pukeko-teammember-widget .teammember-content-wrap p,
.elementor-widget-wp-widget-pukeko-teammember-widget .teammember-social-wrap {
	text-align: center;
}

.elementor-col-100 .elementor-widget-wp-widget-pukeko-teammember-widget .teammember-content-wrap p,
.elementor-col-100 .elementor-widget-wp-widget-pukeko-teammember-widget .teammember-social-wrap {
	text-align: left;
}

.teammember-social-wrap,
.elementor-col-100 .elementor-widget-wp-widget-pukeko-teammember-widget .teammember-social-wrap {
	display: block;
	padding: $m-s-3 0 0 0;
	@include r(st) {
		position: absolute;
		top: 0;
		right: 0;
		padding: 0;
	}
}

.tailor-grid__item .teammember-social-wrap,
.elementor-widget-wp-widget-pukeko-teammember-widget .teammember-social-wrap {
position: relative;
@include r(lt) {
	padding: $d-s-3 0 0 0;
}
}

.teammember-social-wrap a {
	padding: 0 $m-s-3;
	width: 22px;
	height: 22px;
	@include r(st) {
		padding: 0 0 0 $d-s-3;
	}
}

.tailor-grid__item .teammember-social-wrap a {
	padding: 0 $m-s-2;
	width: 18px;
	height: 18px;
	@include r(lt) {
		padding: 0 $d-s-2;
	}
}

.tailor-grid__item .teammember-social-wrap a .icon {
		width: 18px;
		height: 18px;
}

/* ----------------------------------------------------------------------------
 * Content Box
 * ------------------------------------------------------------------------- */
.center {
	text-align: center;
}

.tailor-content p strong,
.elementor-text-editor p strong {
	display: inline-block;
	margin: 0;
	padding: 0 0 $m-s-3;
	color: $dark-1;
	@include r(lt) {
		padding: 0 0 $d-s-3;
	}
}


/* ----------------------------------------------------------------------------
 * Footer
 * ------------------------------------------------------------------------- */

.footer-wrap {
	padding: $m-s-10 0;
	@include r(lt) {
		padding: $d-s-10 0;
	}
}

.site-info, .footer-menu-wrap {
	margin-left: $m-s-4;
	margin-right: $m-s-4;
	@include r(lt) {
		margin-left: $d-s-4;
		margin-right: $d-s-4;
	}
}

.footer-menu-wrap {
	margin-bottom: $m-s-7;
	border-top: 1px solid $light-9;
	border-bottom: 1px solid $light-9;
	@include r(lt) {
		margin-bottom: $d-s-7;
	}
}

.site-info {
	padding: 0;
}


 /* Footer Menu */
.footer-nav {
	padding: $m-s-7 0;
	@include r(lt) {
		margin-top: 3px;
		padding: $d-s-7 0;
		float: left;
	}
}

.footer-nav ul li {
	display: block;
	padding: 0 0 $m-s-4;
	&:last-child {
		 padding: 0;
	}
	@include r(lt) {
		display: inline-block;
		padding: 0 $d-s-4 0 0;
	}
}

/* Footer Social Icons */
.social-footer-nav {
	line-height: 0;
	padding:  $m-s-7 0;
	@include r(lt) {
		float: right;
		padding: $d-s-7 0;
	}
}
















/*------------------------------------------------------------------------------------------------------------
## Media Queries
-------------------------------------------------------------------------------------------------------------*/
/* min. width of 600 pixels
--------------------------------------------------------------------------------------*/
@media screen and (min-width: 37.5em) {
	/*-------------------------------------------------------------- ## Medium Grid --------------------------------------------------------------*/
	.m1, .m2, .m3, .m4, .m5, .m6, .m7, .m8, .m9, .m10, .m11 {
		float: left;
	}
	.m1 {
		width: 8.33333333%;
	}
	.m2 {
		width: 16.6666666%;
	}
	.m3 {
		width: 24.9999999%;
	}
	.m4 {
		width: 33.3333332%;
	}
	.m5 {
		width: 41.6666665%;
	}
	.m6 {
		width: 49.9999998%;
	}
	.m7 {
		width: 58.3333331%;
	}
	.m8 {
		width: 66.6666665%;
	}
	.m9 {
		width: 74.9999998%;
	}
	.m10 {
		width: 83.3333331%;
	}
	.m11 {
		width: 91.6666665%;
	}
	.m12 {
		width: 100%;
		float: none;
	}
}

/* min. width of 960 pixels
--------------------------------------------------------------------------------------*/
@media screen and (min-width: 60em) {

	.menu-toggle {
		display: none;
	}
	.main-navigation {
		padding: 0 $d-s-7 0 0;
		border: none;
		clear: none;
		float: left;
	}
	.main-navigation ul {
		display: block;
	}
	.main-navigation ul li {
		position: relative;
		@include r(lt) {
			float: left;
			margin-right: $d-s-4;
		}
	}
	.main-navigation li:last-child {
		margin-right: 0;
	}
	.main-navigation li a {
		display: block;
		text-decoration: none;
		font-size: 14px;
		font-weight: normal;
		line-height: 1.0;
		color: $dark-1;
		margin: 0;
		padding: 37px 0 11px;
		height: 88px;
		@include  transition-standard;
	}
	.main-navigation li a:hover {
		color: $dark-6;
	}

	/* Main Menu - Dropdpwn */
	.main-navigation ul ul {
		float: left;
		position: absolute;
		top: 64px;
		left: -999em;
		z-index: 99999;
		background: transparent;
		@include shadow-s;
	}
	.main-navigation ul ul ul {
		left: -999em;
		top: 0;
	}
	.main-navigation ul ul.sub-menu a {
		height: auto;
		width: 216px;
		border-top: none;
		padding: 20px 16px;
		background: $white;
		border-bottom: 1px solid $bg-10;
		font-size: 14px;
		line-height: 1.2;
		color: $dark-6;
		border-top: none;
		font-weight: 400;
	}
	.main-navigation ul ul.sub-menu li:last-child a {
		border-bottom: none;
	}
	.main-navigation ul ul.sub-menu ul.sub-menu li a {
		border-bottom: 1px solid $bg-10;
	}
	.main-navigation ul ul.sub-menu ul.sub-menu li:last-child a {
		border-bottom: none;
	}
	.main-navigation ul ul li.menu-item-has-children a {
		position: relative;
	}
	.main-navigation ul ul.sub-menu li.menu-item-has-children ul.sub-menu li a:after {
		display: none;
	}
	.main-navigation ul ul.sub-menu li.menu-item-has-children ul.sub-menu li.menu-item-has-children a:after {
		display: block;
	}
	.main-navigation ul ul.sub-menu li.menu-item-has-children ul.sub-menu li.menu-item-has-children ul.sub-menu li a:after {
		display: none;
	}
	.main-navigation ul ul a:hover {
		background: $bg-10;
		color: $dark-1;
	}
	.main-navigation ul ul li {
		margin-right: 0;
	}
	.main-navigation ul ul li a:hover {
		border-top: none;
	}
	.main-navigation li:hover > a, .main-navigation li.focus > a {
		color: $dark-6;
	}
	.main-navigation ul ul :hover > a, .main-navigation ul ul .focus > a {
		color: $dark-1;
	}
	.main-navigation ul li:hover > ul, .main-navigation ul li.focus > ul {
		left: 0;
	}
	.main-navigation ul ul li:hover > ul, .main-navigation ul ul li.focus > ul {
		left: 100%;
	}

	/* Search Header - Desktop */
.search-header {
	display: inline-block;
	float: left;
	margin: $d-s-4 0 0 0;
	padding: 0 $d-s-3 0 0;
	border-right: solid 1px $dark-9;
	height: 40px;
}
.search-form {
	background: transparent;
}
.search-header .search-field {
	display: inline-block;
	vertical-align: middle;
	width: 1px;
	transition: width .2s,padding .2s;
	cursor: pointer;
	padding-left: 0;
	padding-right: 0;
	height: $d-s-6;
	font-size: 14px;
	font-weight: normal;
}
.search-header .search-field:focus {
		padding-left: $d-s-2;
		width: 180px;
		cursor: text;
}

}




/* min. width of 1600 pixels
--------------------------------------------------------------------------------------*/
@media screen and (min-width: 100.000em) {
	/*-------------------------------------------------------------- ## Large Grid --------------------------------------------------------------*/
	.l1, .l2, .l3, .l4, .l5, .l6, .l7, .l8, .l9, .l10, .l11 {
		float: left;
	}
	.l1 {
		width: 8.33333333%;
	}
	.l2 {
		width: 16.6666666%;
	}
	.l3 {
		width: 24.9999999%;
	}
	.l4 {
		width: 33.3333332%;
	}
	.l5 {
		width: 41.6666665%;
	}
	.l6 {
		width: 49.9999998%;
	}
	.l7 {
		width: 58.3333331%;
	}
	.l8 {
		width: 66.6666665%;
	}
	.single-post.sidebar-show-post .entry-title.l8 {
		width: 100%;
	}
	.l9 {
		width: 74.9999998%;
	}
	.l10 {
		width: 83.3333331%;
	}
	.l11 {
		width: 91.6666665%;
	}
	.l12 {
		width: 100%;
		float: none;
	}
	/*-------------------------------------------------------------- ## Extra Large Grid --------------------------------------------------------------*/
	.xl1, .xl2, .xl3, .xl4, .xl5, .xl6, .xl7, .xl8, .xl9, .xl10, .xl11 {
		float: left;
	}
	.xl1 {
		width: 8.33333333%;
	}
	.xl2 {
		width: 16.6666666%;
	}
	.xl3 {
		width: 24.9999999%;
	}
	.xl4 {
		width: 33.3333332%;
	}
	.xl5 {
		width: 41.6666665%;
	}
	.xl6 {
		width: 49.9999998%;
	}
	.xl7 {
		width: 58.3333331%;
	}
	.xl8 {
		width: 66.6666665%;
	}
	.xl9 {
		width: 74.9999998%;
	}
	.xl10 {
		width: 83.3333331%;
	}
	.xl11 {
		width: 91.6666665%;
	}
	.xl12 {
		width: 100%;
		float: none;
	}
}
