/* ----------------------------------------------------------------------------
 * Mixins
 * ------------------------------------------------------------------------- */


 /* ----------------------------------------------------------------------------
	* Responsive (reference: https://material.io/guidelines/layout/responsive-ui.html#responsive-ui-breakpoints)
	* Usage

		 @include r(600)  { } st = small tablet
		 @include r(840)  { } lt = large tablet
		 @include r(960) { }  sd = small desktop
		 @include r(1248) { } d = desktop
		 @include r(1600) { } ld = large desktop

	* ------------------------------------------------------------------------- */

	@mixin r($class) {
		@if $class == st {
			@media (min-width: 37.5em) { @content; }
		}

		@else if $class == lt {
			@media (min-width: 52.500em) { @content; }
		}

		@else if $class == sd {
			@media (min-width: 60.000em) { @content; }
		}

		@else if $class == d {
			@media (min-width: 78.000em) { @content; }
		}

		@else if $class == ld {
			@media (min-width: 100.000em) { @content; }
		}

		@else {
			@warn "Breakpoint mixin supports: st, lt, sd, ld";
		}
	}



/* ----------------------------------------------------------------------------
 * Fonts
 * ------------------------------------------------------------------------- */

@mixin f1 { font-family: "Roboto", sans-serif;}

@mixin f2 {font-family: "Cardo", serif;}

@mixin f-code {font-family: "Courier 10 Pitch", Courier, monospace;}


/* ----------------------------------------------------------------------------
 * mixin for Mobile Font Sizes
 * ------------------------------------------------------------------------- */

// Mobile sizes font 1 - Primary:
@mixin f1-hero-m {
	font-size: 35px;
	font-weight: 300;
	line-height: 1.26;
}
@mixin f1-h1-m {
	font-size: 32px;
	font-weight: 300;
	line-height: 1.38;
}
@mixin f1-h2-m {
	font-size: 29px;
	font-weight: 300;
	line-height: 1.24;
}
@mixin f1-h3-m {
	font-size: 27px;
	font-weight: 300;
	line-height: 1.22;
}
@mixin f1-h4-m {
	font-size: 24px;
	font-weight: 300;
	line-height: 1.24;
	letter-spacing: 0;
}
@mixin f1-h5-m {
	font-size: 20px;
	font-weight: 300;
	line-height: 1.3;
	letter-spacing: 0;
}
@mixin f1-h6-m {
	font-size: 17px;
	line-height: 1.29;
	font-weight: 300;
	letter-spacing: 0;
}
@mixin f1-body1-m {
	font-size: 22px;
	font-weight: 300;
	line-height: 1.54;
}
@mixin f1-body2-m {
	font-size: 18px;
	font-weight: 300;
	line-height: 1.78;
}
@mixin f1-body3-m {
	font-size: 15px;
	font-weight: 300;
	line-height: 1.53;
	letter-spacing: 0;
}
@mixin f1-body4-m {
	font-size: 13px;
	font-weight: 300;
	line-height: 1.54;
	letter-spacing: 0;
}
@mixin f1-body5-m {
	font-size: 11px;
	font-weight: 300;
	line-height: 1.36;
}
@mixin f1-uppercase1-m {
	font-size: 12px;
	font-weight: 500;
	line-height: 1.0;
	text-transform: uppercase;
	letter-spacing: 0;
}
@mixin f1-uppercase2-m {
	font-size: 10px;
	font-weight: 500;
	line-height: 1.0;
	text-transform: uppercase;
	letter-spacing: 0;
}
@mixin f1-btn1-m {
	font-size: 14px;
	font-weight: normal;
}
@mixin f1-btn2-m {
	font-size: 13px;
	font-weight: normal;
}
@mixin f1-btn3-m {
	font-size: 12px !important;
	font-weight: normal;
	line-height: 1;
}
@mixin f1-sitetitle-m {
	font-size: 18px;
	font-weight: 500;
	line-height: 1;
	letter-spacing: 1px;
}
@mixin f1-widgettitle-m {
	font-size: 13px;
	font-weight: 500;
	line-height: 1.54;
	letter-spacing: 0;
}

// Mobile sizes font 1 - Secondary:
@mixin f1-body2-2-m {
	font-size: 18px;
	font-weight: 300;
	line-height: 1.78;
	color: $dark-6;
}
@mixin f1-body3-2-m {
	font-size: 15px;
	font-weight: 300;
	line-height: 1.53;
	color: $dark-6;
}
@mixin f1-body4-2-m {
	font-size: 13px;
	font-weight: 300;
	line-height: 1.54;
	color: $dark-6;
}
@mixin f1-body5-2-m {
	font-size: 11px;
	font-weight: 300;
	line-height: 1.36;
	color: $dark-6;
}
@mixin f1-uppercase2-2-m {
	font-size: 10px;
	font-weight: 500;
	line-height: 1.0;
	color: $dark-6;
}
@mixin f1-btn1-2-m {
	font-size: 14px;
	font-weight: normal;
	color: $dark-6;
}
@mixin f1-btn2-2-m {
	font-size: 13px;
	font-weight: normal;
	color: $dark-6;
}
@mixin f1-btn3-2-m {
	font-size: 12px !important;
	font-weight: normal;
	line-height: 1;
	color: $bg-6;
}

 // Mobile sizes font 2  - Primary:
 @mixin f2-hero-m {
	font-size: 37px;
	line-height: 1.19;
 }
 @mixin f2-h1-m {
	font-size: 34px;
	line-height: 1.29;
 }
 @mixin f2-h2-m {
	font-size: 31px;
	line-height: 1.16;
 }
 @mixin f2-h3-m {
	font-size: 29px;
	line-height: 1.14;
 }
 @mixin f2-h4-m {
	font-size: 27px;
	line-height: 1.15;
 }
 @mixin f2-h5-m {
	font-size: 22px;
	line-height: 1.18;
 }
 @mixin f2-h6-m {
	font-size: 19px;
	line-height: 1.16;
 }


 /* ----------------------------------------------------------------------------
	* mixin for Desktop Font Sizes
	* ------------------------------------------------------------------------- */

// Desktop sizes font 1 - Primary:
@mixin f1-hero-d {
	font-size: 62px;
	line-height: 1.16;
}
@mixin f1-h1-d {
	font-size: 54px;
	line-height: 1.24;
}
@mixin f1-h2-d {
	font-size: 46px;
	line-height: 1.26;
}
@mixin f1-h3-d {
	font-size: 34px;
	line-height: 1.38;
}
@mixin f1-h4-d {
	font-size: 27px;
}
@mixin f1-h5-d {
	font-size: 22px;
	line-height: 1.32;
}
@mixin f1-h6-d {
	font-size: 18px;
	line-height: 1.33;
}
@mixin f1-body1-d {
	font-size: 26px;
	line-height: 1.54;
}
@mixin f1-body2-d {
	font-size: 19px;
	line-height: 1.79;
}
@mixin f1-body3-d {
	font-size: 16px;
	line-height: 1.56;
}
@mixin f1-body4-d {
	font-size: 14px;
	line-height: 1.57;
}
@mixin f1-body5-d {
	font-size: 12px;
	line-height: 1.42;
}
@mixin f1-uppercase1-d {
	font-size: 13px;
}
@mixin f1-uppercase2-d {
	font-size: 11px;
}
@mixin f1-btn1-d {
	font-size: 15px;
}
@mixin f1-btn2-d {
	font-size: 14px;
}
@mixin f1-btn3-d {
	font-size: 13px !important;
}
@mixin f1-sitetitle-d {
	font-size: 20px;
	line-height: 1.0;
}
@mixin f1-widgettitle-d {
	font-size: 14px;
	line-height: 1.57;
}

// Desktop sizes font 1 - Secondary:
@mixin f1-body2-2-d {
	font-size: 19px;
	line-height: 1.79;
}
@mixin f1-body3-2-d {
	font-size: 16px;
	line-height: 1.56;
}
@mixin f1-body4-2-d {
	font-size: 14px;
	line-height: 1.57;
}
@mixin f1-body5-2-d {
	font-size: 12px;
	line-height: 1.42;
}
@mixin f1-uppercase2-2-d {
	font-size: 11px;
}
@mixin f1-btn1-2-d {
	font-size: 15px;
}
@mixin f1-btn2-2-d {
	font-size: 14px;
}
@mixin f1-btn3-2-d {
	font-size: 13px !important;
}

 // Desktop sizes font 2  - Primary:
@mixin f2-hero-d {
	font-size: 64px;
	line-height: 1.12;
	letter-spacing: 2.1px;
}
@mixin f2-h1-d {
	font-size: 56px;
	line-height: 1.2;
	letter-spacing: 1.8px;
}
@mixin f2-h2-d {
	font-size: 48px;
	line-height: 1.21;
	letter-spacing: 1.5px;
}
 @mixin f2-h3-d {
	font-size: 40px;
	line-height: 1.17;
	letter-spacing: 1.2px;
}
@mixin f2-h4-d {
	font-size: 29px;
	line-height: 1.24;
	letter-spacing: 0.9px;
}
@mixin f2-h5-d {
	font-size: 24px;
	line-height: 1.21;
	letter-spacing: 0.6px;
}
@mixin f2-h6-d {
	font-size: 20px;
	line-height: 1.2;
	letter-spacing: 0.3px;
}


/* ----------------------------------------------------------------------------
 * mixin for Buttons
 * ------------------------------------------------------------------------- */

 // Buttons General:
@mixin btn {
	display: inline-block;
	vertical-align: middle;
	font-weight: 500;
	text-transform: uppercase;
	line-height: 1;
	@include transition-btn;
}

// Buttons Sizes:
@mixin btn-xl {
	height: $stickyheader-d;
	padding: 38px $d-s-7;
	font-size: 13px;
	text-transform: uppercase;
}

@mixin btn-l {
	height: $d-s-9;
	padding: 30px $d-s-6;
	font-size: 13px;
	text-transform: uppercase;
}

@mixin btn-m {
	display: inline-block;
	height: $d-s-8;
	padding: 22px $d-s-5;
	font-size: 13px;
	text-transform: uppercase;
}

@mixin btn-s {
	height: $d-s-7;
	padding: 18px $d-s-4;
	font-size: 12px;
	text-transform: uppercase;
}

@mixin btn-xs {
	height: $d-s-6;
	padding: 14px $d-s-3;
	font-size: 12px;
	text-transform: uppercase;
}

// Buttons States:
@mixin btn-primary {
	color: $white;
	fill: $white;
	background-color: $blue;
	@include transition-btn;
	&:hover {
		background-color: $white;
		color: $blue;
		@include shadow-s;
		}
}

@mixin btn-secondary {
	background-color: $bg-9;
	@include transition-btn;
	&:hover {
		background-color: $bg-8;
	}
}

@mixin btn-flat {
	background-color: transparent;
	@include transition-btn;
	&:hover {
		color: $blue;
	}
}

@mixin btn-ghost {
	border: solid 2px $blue;
	@include transition-btn;
	&:hover {
		color: $white;
		background-color: $blue;
		@include shadow-s;
	}
}


/* ----------------------------------------------------------------------------
 * mixin for Avatar Images
 * ------------------------------------------------------------------------- */
@mixin avatar-xs {
	width: $m-s-5;
	height: $m-s-5;
	@include r(lt) {
		width: $d-s-5;
		height: $d-s-5;
	}
}

@mixin avatar-s {
	width: $m-s-6;
	height: $m-s-6;
	@include r(lt) {
		width: $d-s-6;
		height: $d-s-6;
	}
}

@mixin avatar-m {
	width: $m-s-7;
	height: $m-s-7;
	@include r(lt) {
		width: $d-s-7;
		height: $d-s-7;
	}
}

@mixin avatar-l {
	width: $m-s-8;
	height: $m-s-8;
	@include r(lt) {
		width: $d-s-8;
		height: $d-s-8;
	}
}

@mixin avatar-xl {
	width: $m-s-9;
	height: $m-s-9;
	@include r(lt) {
		width: $d-s-9;
		height: $d-s-9;
	}
}

@mixin avatar-xxl {
	width: $m-s-10;
	height: $m-s-10;
	@include r(lt) {
		width: $d-s-10;
		height: $d-s-10;
	}
}


/* ----------------------------------------------------------------------------
 * mixin for clearfix
 * @include clearfix
 * ------------------------------------------------------------------------- */
@mixin clearfix {
	&:before,
	&:after {
				content: " ";
				display: table;
		}
	&:after {
				clear: both;
		}
}


/* ----------------------------------------------------------------------------
 * Mixin for Placeholders
 * @include input-placeholder;
 * ------------------------------------------------------------------------- */
@mixin input-placeholder {
	&.placeholder { @content; }
	&:-moz-placeholder { @content; }
	&::-moz-placeholder { @content; }
	&:-ms-input-placeholder { @content; }
	&::-webkit-input-placeholder { @content; }
}

/* ----------------------------------------------------------------------------
* Mixin for Centering
* @include push--auto;
 * ------------------------------------------------------------------------- */
@mixin push--auto {
	margin: {
			left: auto;
			right: auto;
	}
}


/* ----------------------------------------------------------------------------
* Mixin grid padding (16px mobile and 24px from larger tablets)
* @include push--auto;
 * ------------------------------------------------------------------------- */
@mixin col-padding {
	padding-left: $m-s-4;
	padding-right: $m-s-4;
	@include r(lt) {
		padding-left: $d-s-4;
		padding-right: $d-s-4;
	}
}


 /* ----------------------------------------------------------------------------
	* mixin for border radius
	* ------------------------------------------------------------------------- */

@mixin border-radius($radius) {
-webkit-border-radius: $radius;
	 -moz-border-radius: $radius;
		-ms-border-radius: $radius;
				border-radius: $radius;
}


@mixin hide-rounded {
	 -webkit-appearance: none;
-webkit-border-radius: 0;
				border-radius: 0;
}

/* ----------------------------------------------------------------------------
* Transitions
* ------------------------------------------------------------------------- */

@mixin transition-standard {
-webkit-transition: all 0.2s ease;
				transition: all 0.2s ease;
}

@mixin transition-mainnav {
-webkit-transition: all 0.3s;
				transition: all 0.3s;
}

@mixin transition-posthover {
-webkit-transition: all 0.5s ease;
				transition: all 0.5s ease;
}

@mixin transition-btn {
	-webkit-transition: all 0.7s cubic-bezier(0.23,1,0.32,1);
		 -moz-transition: all 0.7s cubic-bezier(0.23,1,0.32,1);
					transition: all 0.7s cubic-bezier(0.23,1,0.32,1);
}

/* ----------------------------------------------------------------------------
* Shadows
* ------------------------------------------------------------------------- */

/* Default Shadows */
@mixin shadow-s {
	box-shadow: 0 3px 16px rgba(31, 32, 68,.15);
}
@mixin shadow-m {
	box-shadow: 0 8px 20px 0 rgba(31, 32, 68, 0.25);
}

/* Special Shadows */
@mixin shadow-mainnav {
	box-shadow: 0px 2px 30px 0px rgba(31, 32, 68,0.08);
}
@mixin shadow-formsfocus {
	box-shadow: 0 2px 4px 0 rgba(31, 32, 68, .1);
}

/* ----------------------------------------------------------------------------
* Scaling
* ------------------------------------------------------------------------- */

@mixin posthover-scale-default {
	-webkit-transform: scale(1.01);
			-ms-transform: scale(1.01);
					transform: scale(1.01);
}


/* ----------------------------------------------------------------------------
 * Avatars
 * ------------------------------------------------------------------------- */



/* ----------------------------------------------------------------------------
* IMAGE RETINA
* @include image-2x(/img/image.png, 100%, auto);
* ------------------------------------------------------------------------- */
@mixin image-2x($image, $width, $height) {
	@media (min--moz-device-pixel-ratio: 1.3),
				 (-o-min-device-pixel-ratio: 2.6/2),
				 (-webkit-min-device-pixel-ratio: 1.3),
				 (min-device-pixel-ratio: 1.3),
				 (min-resolution: 1.3dppx) {
		background-image: url($image);
		background-size: $width $height;
	}
}
